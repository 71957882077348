import React from "react";
import PropTypes from "prop-types";
import Container from "@components/Container";

import "./style.css";

const ParagraphCenter = ({ originalContent, includeGrids }) => (
  <div className="paragraph-center">
    {includeGrids ? (
      <Container
        variant="small"
        dangerouslySetInnerHTML={{ __html: originalContent }}
      />
    ) : (
      <div dangerouslySetInnerHTML={{ __html: originalContent }} />
    )}
  </div>
);

ParagraphCenter.propTypes = {
  originalContent: PropTypes.string.isRequired,
};

export default ParagraphCenter;
