import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FadeInOnView from "@components/FadeInOnView";

import "./style.css";

const DEFAULT_HEADER_COLOR = "auto";
const DEFAULT_MARGIN_SETTING = "normal";

const Stats = ({ acf }) => {
  const title = acf?.title ?? "";

  const headerColor = acf?.stats_headerColor ?? DEFAULT_HEADER_COLOR;
  const marginTop = acf?.stats_marginTop ?? DEFAULT_MARGIN_SETTING;
  const marginBottom = acf?.stats_marginBottom ?? DEFAULT_MARGIN_SETTING;

  let stats = acf?.stats ?? [];

  // NOTE: monkey patch to ignore empty detail entries
  let isDetailed = false;
  stats = stats.map(result => {
    if (!result.details) {
      return result;
    }
    result.details = result.details.filter(
      detail => detail.value && detail.description
    );
    if (result.details.length > 0) {
      isDetailed = true;
    }

    return result;
  });

  const classes = classnames(
    "stats",
    `header-color--${headerColor}`,
    `margin-top--${marginTop}`,
    `margin-bottom--${marginBottom}`,
    {
      "stats--detailed": isDetailed,
    }
  );

  return (
    <div className={classes}>
      {title && <h4 className="stats__heading">{title}</h4>}
      <ul className="stats__results">
        {stats.map((result, idx) => {
          const detailsBlock = (
            <FadeInOnView translate="medium">
              <ul className="stats__result__details">
                {result.details &&
                  result.details.map((detail, idx) => (
                    <li className="stats__info" key={idx}>
                      <div>{detail.value}</div>
                      <p>{detail.description}</p>
                    </li>
                  ))}
              </ul>
            </FadeInOnView>
          );
          const extendedDetailsBlock = (
            <ul className="stats__result__details">
              {result.details &&
                result.details.map((detail, idx) => (
                  <div className="stats__result__details__wrapper" key={idx}>
                    <div className="stats__info">{detail.value}</div>
                    <p>{detail.description}</p>
                  </div>
                ))}
            </ul>
          );

          const hasDetails = result.details && result.details.length > 0;

          const isExtended = result.extended;

          return (
            <li
              className={classnames("stats__result", {
                opened: isExtended,
                extended: isExtended,
              })}
              key={idx}
            >
              <FadeInOnView>
                <div className="stats__info">
                  <div className="stats__info__big">
                    <div className="stats__info--number">{result.value}</div>
                    <p>{result.description}</p>
                  </div>
                  {result.extended && (
                    <p className="stats__info__paragraph">{result.paragraph}</p>
                  )}
                </div>
                {hasDetails && !isExtended
                  ? detailsBlock
                  : result.details.length > 0
                  ? extendedDetailsBlock
                  : null}
              </FadeInOnView>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Stats.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default Stats;
