import classnames from "classnames";
import React, { useRef, useCallback, useEffect, useState } from "react";
import useInView from "use-in-view";

import usePreviousState from "@utils/usePreviousState";

const SLIDE_X_DAMPING = 0.1;

const Slider = ({ images }) => {
  const [inViewRef, isInView] = useInView();
  const wrapperRef = useRef();
  const innerRef = useRef();

  const [index, setIndex] = usePreviousState(0);

  const progressCursorRef = useRef(null);

  const animation = useRef({
    raf: null,
    isInView,
    currentX: 0,
    targetX: 0,
    lastX: 0,
    isUserScrolling: false,
  });

  useEffect(() => {
    animation.current.isInView = isInView;
  }, [isInView]);

  const animate = useCallback(() => {
    if (!animation.current.raf) {
      return;
    }

    animation.current.currentX +=
      (animation.current.targetX - animation.current.currentX) *
      SLIDE_X_DAMPING;

    innerRef.current.style.transform = `translateX(${animation.current.currentX}px)`;

    if (animation.current.isInView) {
      animation.current.raf = requestAnimationFrame(animate);
    }
  }, []);

  useEffect(() => {
    if (isInView) {
      animation.current.raf = requestAnimationFrame(animate);
    }

    return () => {
      animation.current.raf = cancelAnimationFrame(animation.current.raf);
    };
  }, [isInView, animate]);

  // handlers for scroll mode
  const handlePointerMove = useCallback(
    e => {
      // get pointer x based on the event type
      const pointerX =
        e.type === "touchmove" ? e.touches[0].clientX : e.clientX;

      // calculate the difference between the initial x position and the current one
      animation.current.scrollDeltaX =
        pointerX - animation.current.scrollStartX;
      animation.current.targetX =
        animation.current.lastX + animation.current.scrollDeltaX;

      // update the progress cursor
      progressCursorRef.current.style.transform = `translateX(${(index.current /
        images.length) *
        100}%) translateX(${-animation.current.scrollDeltaX /
        images.length}px) scaleX(${1 / images.length})`;
    },
    [index]
  );
  const handlePointerUp = useCallback(
    e => {
      if (animation.current.scrollDeltaX !== 0) {
        const scrollDuration = Date.now() - animation.current.scrollStartTime;

        // get scroll speed
        const scrollSpeed = Math.abs(
          animation.current.scrollDeltaX / scrollDuration
        );

        if (
          scrollSpeed > 0.3 ||
          Math.abs(animation.current.scrollDeltaX) > 100
        ) {
          const newIndex = Math.max(
            0,
            Math.min(
              animation.current.scrollDeltaX > 0
                ? index.current - 1
                : index.current + 1,
              images.length - 1
            )
          );

          // update the progress cursor
          progressCursorRef.current.style.transform = `translateX(${(newIndex /
            images.length) *
            100}%) scaleX(${1 / images.length})`;

          animation.current.targetX =
            -newIndex * wrapperRef.current.clientWidth;

          // set the new index
          setIndex(newIndex);
        } else {
          animation.current.targetX =
            -index.current * wrapperRef.current.clientWidth;

          // update the progress cursor
          progressCursorRef.current.style.transform = `translateX(${(index.current /
            images.length) *
            100}%) scaleX(${1 / images.length})`;
        }
      }

      animation.current.isUserScrolling = false;

      // unregister handlers
      if (e.type !== "mouseup") {
        window.removeEventListener("touchmove", handlePointerMove);
        window.removeEventListener("touchend", handlePointerUp);
        window.removeEventListener("touchcancel", handlePointerUp);
      } else {
        window.removeEventListener("mousemove", handlePointerMove);
        window.removeEventListener("mouseup", handlePointerUp);
      }
    },
    [handlePointerMove, index.current]
  );
  const handlePointerDown = useCallback(
    e => {
      // get pointer x based on the event type
      const pointerX =
        e.type === "touchstart" ? e.touches[0].clientX : e.clientX;

      // save initial x position
      animation.current.isUserScrolling = true;
      animation.current.scrollStartTime = Date.now();
      animation.current.scrollStartX = pointerX;
      animation.current.lastX = animation.current.currentX;
      animation.current.targetX = animation.current.currentX;

      // register handlers
      if (e.type === "touchstart") {
        window.addEventListener("touchmove", handlePointerMove);
        window.addEventListener("touchend", handlePointerUp);
        window.addEventListener("touchcancel", handlePointerUp);
      } else {
        window.addEventListener("mousemove", handlePointerMove);
        window.addEventListener("mouseup", handlePointerUp);
      }
    },
    [handlePointerMove, handlePointerUp]
  );

  useEffect(() => {
    return () => {
      // ensure that the event handlers are removed when the component is
      // unmounted
      window.removeEventListener("mousemove", handlePointerMove);
      window.removeEventListener("mouseup", handlePointerUp);
      window.removeEventListener("touchmove", handlePointerMove);
      window.removeEventListener("touchend", handlePointerUp);
      window.removeEventListener("touchcancel", handlePointerUp);
    };
  }, [handlePointerUp, handlePointerMove]);

  return (
    <div
      className="playground-detail-slider"
      ref={ref => {
        wrapperRef.current = ref;
        inViewRef(ref);
      }}
      onTouchStart={handlePointerDown}
      onMouseDown={handlePointerDown}
    >
      <div ref={innerRef} className="playground-detail-slider__inner">
        {images.map(({ url, width, height }, imageIndex) => (
          <div
            key={url}
            className={classnames("playground-detail-slider__item", {
              "playground-detail-slider__item--left-aligned":
                imageIndex === index.current + 1 ||
                (imageIndex === index.current &&
                  index.previous < index.current),
              "playground-detail-slider__item--right-aligned":
                imageIndex === index.current - 1 ||
                (imageIndex === index.current &&
                  index.previous > index.current),
              "playground-detail-slider__item--active":
                imageIndex === index.current,
            })}
            style={{
              backgroundImage: `url(${url})`,
            }}
            onClick={() => {
              if (
                imageIndex === index.current - 1 ||
                imageIndex === index.current + 1
              ) {
                animation.current.targetX =
                  -imageIndex * wrapperRef.current.clientWidth;

                setIndex(imageIndex);
              }
            }}
          />
        ))}
      </div>

      <div className="playground-detail-slider__progress-wrapper">
        <div className="playground-detail-slider__progress">
          <div
            ref={progressCursorRef}
            className="playground-detail-slider__progress-cursor"
            style={{
              transform: `translateX(${(index.current / images.length) *
                100}%) scaleX(${1 / images.length})`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Slider;
