import React from "react";
import useInView from "use-in-view";

export default function AnimateLine(props) {
  const [ref, isInView] = useInView();

  return (
    <section className={isInView ? "line-animate" : ""} ref={ref}>
      <svg
        id="arrow-line-desktop-clients"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        viewBox="0 0 693 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M691 19.5678C614.056 -24.792 643.535 66.6658 454.09 24.8143C305.818 -7.94121 164.472 55.0364 195.42 61.1043C226.368 67.1721 134.467 -90.5446 5 82L1 68L4.5 84L21 79.5"
          stroke="#EC5D57"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </svg>

      <svg
        id="arrow-line-mobile-clients"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        viewBox="0 0 267 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M265 8.83744C165.443 -20.4307 90.4963 41.2367 112.004 47.6183C133.511 54 79.4457 -33.2849 3 54L1.5 43.5L2.5 56L13.5 53"
          stroke="#EC5D57"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </svg>
    </section>
  );
}
