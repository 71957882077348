import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";

import Container from "@components/Container";
import FadeInOnView from "@components/FadeInOnView";

import "./style.css";

const DEFAULT_TEXT_COLOR = "black";
const DEFAULT_BACKGROUND_COLOR = "white";

const DEFAULT_HEADER_COLOR = "auto";

const ProjectIntroText = ({ acf }) => {
  const tags = acf?.tags ?? [];
  const intro = acf?.intro ?? "";
  const containerStyle = {
    backgroundColor:
      acf?.projectIntroText_backgroundColor ?? DEFAULT_BACKGROUND_COLOR,
    color: acf?.projectIntroText_textColor ?? DEFAULT_TEXT_COLOR,
  };
  const headerColor = acf?.projectIntroText_headerColor ?? DEFAULT_HEADER_COLOR;

  return (
    <FadeInOnView
      className={classnames(
        "project-intro-text",
        `header-color--${headerColor}`
      )}
      style={containerStyle}
    >
      <Container variant="small">
        <ul>
          {tags.map((tag, idx) => (
            <li key={idx}>{`${idx > 0 ? " | " : ""}${tag.tag}`}</li>
          ))}
        </ul>

        <div
          className="project-intro-text--copy"
          dangerouslySetInnerHTML={{ __html: intro }}
        />
      </Container>
    </FadeInOnView>
  );
};

ProjectIntroText.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default ProjectIntroText;
