import React from "react";
import useInView from "use-in-view";
import AnimatedText from "@components/AnimatedText";

const AnimatedTextOnView = props => {
  const [ref, isInView] = useInView();

  return <AnimatedText innerRef={ref} {...props} fadeIn={isInView} />;
};

AnimatedTextOnView.defaultProps = {
  delay: 240,
  blockDelay: 500,
  easing: "var(--t--ease-out-quint)",
  duration: 2000,
  onFadedIn: f => f,
};

export default AnimatedTextOnView;
