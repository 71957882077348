
//
// This file is generated onPreExtractQueries
//
import gql from "graphql-tag";

export const previewFragments = gql`

  # Fragment ArrowsAnimated block found in "src/blocks/ArrowsAnimated/.graphql"
  fragment Roll_BlockArrowAnimated on WPGraphQL_AcfArrowsBlock {
    acf {
      arrow_typology
      arrows_headerColor: headerColor
    }
  }

  # Fragment Awards block found in "src/blocks/Awards/.graphql"
  fragment Roll_BlockAwards on WPGraphQL_AcfAwardsBlock {
    acf {
      title
      description
      awards {
        name
        icon {
          mediaItemUrl
          mediaDetails {
            width
            height
          }
        }
        count
      }
      textColor
      backgroundColor
      awards_headerColor: headerColor
    }
  }

  # Fragment BannerFullWidth block found in "src/blocks/BannerFullWidth/.graphql"
  fragment Roll_BlockBannerFullWidth on WPGraphQL_AcfBannerFullWidthBlock {
    acf {
      media {
        mediaItemUrl
        mediaType
      }
      imageFit
      bannerFull_headerColor: headerColor
    }
  }

  # Fragment BannerImageThree block found in "src/blocks/BannerImageThree/.graphql"
  fragment Roll_BlockBannerImageThree on WPGraphQL_AcfBannerImageThreeBlock {
    acf {
      leftMedia {
        mediaItemUrl
        mediaType
      }
      middleMedia {
        mediaItemUrl
        mediaType
      }
      rightMedia {
        mediaItemUrl
        mediaType
      }
      bannerImageThree_marginTop: marginTop
      bannerImageThree_marginBottom: marginBottom
      bannerImageThree_headerColor: headerColor
    }
  }

  # Fragment FourImagesModule block found in "src/blocks/FourImagesModule/.graphql"
  fragment Roll_BlockFourImagesModule on WPGraphQL_AcfFourImagesModuleBlock {
    acf {
      bottomLeft {
        caption
        media {
          mediaType
          mediaItemUrl
        }
      }
      bottomRight {
        caption
        media {
          mediaItemUrl
          mediaType
        }
      }
      topLeft {
        caption
        media {
          mediaItemUrl
          mediaType
        }
      }
      topRight {
        caption
        media {
          mediaType
          mediaItemUrl
        }
      }
      fourImagesModule_backgroundColor: backgroundColor
      fourImagesModule_textColor: textColor
      fourImagesModule_headerColor: headerColor
      fourImagesModule_marginTop: marginTop
      fourImagesModule_marginBottom: marginBottom
    }
  }

  # Fragment Heading block found in "src/blocks/Heading/.graphql"
  fragment Roll_BlockHeading on WPGraphQL_CoreHeadingBlock {
    originalContent
  }

  # Fragment Marquee block found in "src/blocks/Marquee/.graphql"
  fragment Roll_BlockMarquee on WPGraphQL_AcfMarqueeBlock {
    acf {
      text
      marquee_headerColor: headerColor
    }
  }

  # Fragment MediaFrame block found in "src/blocks/MediaFrame/.graphql"
  fragment Roll_BlockMediaFrame on WPGraphQL_AcfMediaFrameBlock {
    acf {
      media {
        mediaItemUrl
        mediaType
      }
      alternativeMedia {
        mediaItemUrl
        mediaType
      }
      backgroundColor
      framed
      paddingBottomNone
      link {
        url
        linksToVideo
      }
      mediaFrame_marginTop: marginTop
      mediaFrame_marginBottom: marginBottom
      mediaFrame_headerColor: headerColor
    }
  }

  # Fragment MediaGallery block found in "src/blocks/MediaGallery/.graphql"
  fragment Roll_BlockMediaGallery on WPGraphQL_AcfMediaGalleryBlock {
    acf {
      title
      gallery {
        media {
          file {
            mediaItemUrl
            mediaType
          }
          aspectRatio
        }
      }
      mediaGallery_textColor: textColor
      mediaGallery_backgroundColor: backgroundColor
      mediaGallery_marginTop: marginTop
      mediaGallery_marginBottom: marginBottom
      mediaGallery_headerColor: headerColor
    }
  }

  # Fragment MediaText block found in "src/blocks/MediaText/.graphql"
  fragment Roll_BlockMediaText on WPGraphQL_AcfMediaTextBlock {
    acf {
      title
      text
      reversed
      media {
        mediaItemUrl
        mediaType
      }
      textPosition
      textAlignment
      mediaText_textColor: textColor
      mediaText_backgroundColor: backgroundColor
      mediaText_marginTop: marginTop
      mediaText_marginBottom: marginBottom
      mediaText_headerColor: headerColor
    }
  }

  # Fragment MediaTextGallery block found in "src/blocks/MediaTextGallery/.graphql"
  fragment Roll_BlockMediaTextGallery on WPGraphQL_AcfMediaTextGalleryBlock {
    acf {
      title
      topText
      bottomText
      reversed
      onaboutpage
      mediaResources {
        media {
          mediaItemUrl
          mediaType
        }
      }
      mediaTextGallery_textColor: textColor
      mediaTextGallery_backgroundColor: backgroundColor
      mediaTextGallery_marginTop: marginTop
      mediaTextGallery_marginBottom: marginBottom
      mediaTextGallery_headerColor: headerColor
    }
  }

  # Fragment MoodboardImages block found in "src/blocks/MoodboardImages/.graphql"
  fragment Roll_BlockMoodboardImages on WPGraphQL_AcfMoodboardImagesBlock {
    acf {
      images {
        image {
          url: mediaItemUrl
        }
      }
      moodboard_headerColor: headerColor
    }
  }

  # Fragment ParagraphCenter block found in "src/blocks/ParagraphCenter/.graphql"
  fragment Roll_BlockParagraph on WPGraphQL_CoreParagraphBlock {
    originalContent
  }

  # Fragment Playground block found in "src/blocks/Playground/.graphql"
  fragment Roll_BlockPlayground on WPGraphQL_AcfPlaygroundBlock {
    acf {
      title
      description
  
      items {
        title
        coverTitle: cover_title
        year
        description
        link {
          title
          url
          target
        }
        credits
        images {
          image {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
  
      playground_headerColor: headerColor
    }
  }

  # Fragment ProjectCover block found in "src/blocks/ProjectCover/.graphql"
  fragment Roll_BlockProjectCover on WPGraphQL_AcfProjectCoverBlock {
    acf {
      image {
        sourceUrl(size: _1536X1536)
      }
      alternativeImage {
        sourceUrl(size: _1536X1536)
      }
      projectName
      tagline
      projectCover_headerColor: headerColor
      projectCover_textColor: textColor
    }
  }

  # Fragment ProjectIntroText block found in "src/blocks/ProjectIntroText/.graphql"
  fragment Roll_BlockProjectIntroText on WPGraphQL_AcfProjectIntroTextBlock {
    acf {
      tags {
        tag
      }
      intro
      projectIntroText_textColor: textColor
      projectIntroText_BackgroundColor: backgroundColor
      projectIntroText_headerColor: headerColor
    }
  }

  # Fragment Quotes block found in "src/blocks/Quotes/.graphql"
  fragment Roll_BlockQuotes on WPGraphQL_AcfQuotesBlock {
    acf {
      quotes {
        author
        text
      }
      cursorTextColor
      quotes_textColor: textColor
      quotes_backgroundColor: backgroundColor
      quotes_headerColor: headerColor
      quotes_marginTop: marginTop
      quotes_marginBottom: marginBottom
    }
  }

  # Fragment Stats block found in "src/blocks/Stats/.graphql"
  fragment Roll_BlockStats on WPGraphQL_AcfStatsBlock {
    acf {
      title
      stats {
        value
        description
        details {
          value
          description
        }
        paragraph
        extended
      }
      stats_marginTop: marginTop
      stats_marginBottom: marginBottom
      stats_headerColor: headerColor
    }
  }

  # Fragment Team block found in "src/blocks/Team/.graphql"
  fragment Roll_BlockTeam on WPGraphQL_AcfTeamBlock {
    acf {
      title
      description
      textColor
      backgroundColor
      team_headerColor: headerColor
    }
  }

  # Fragment TechFeatures block found in "src/blocks/TechFeatures/.graphql"
  fragment Roll_BlockTechFeatures on WPGraphQL_AcfTechFeaturesBlock {
    acf {
      title
      features {
        column_1
        column_2
        column_3
      }
      techFeatures_textColor: textColor
      techFeatures_backgroundColor: backgroundColor
      techFeatures_headerColor: headerColor
      techFeatures_marginTop: marginTop
      techFeatures_marginBottom: marginBottom
    }
  }

  # Fragment TitleText block found in "src/blocks/TitleText/.graphql"
  fragment Roll_BlockTitleText on WPGraphQL_AcfTitleTextBlock {
    acf {
      title
      text
      textCenter
      titleText_textColor: textColor
      titleText_backgroundColor: backgroundColor
      titleText_marginTop: marginTop
      titleText_marginBottom: marginBottom
      titleText_headerColor: headerColor
      titleText_wrapInGrid: wrapInGrid
      titleText_hasNarrowMargins: hasNarrowMargins
      titleText_isFluid: isFluid
      titleText_mobileOffset: mobileOffset
      titleText_mobileColumns: mobileColumns
      titleText_desktopOffset: desktopOffset
      titleText_desktopColumns: desktopColumns
    }
  }


  # Roll_Block fragment wrapping all the others
  fragment Roll_Block on WPGraphQL_Block {
    name

    # Block fragments
    ...Roll_BlockArrowAnimated
    ...Roll_BlockAwards
    ...Roll_BlockBannerFullWidth
    ...Roll_BlockBannerImageThree
    ...Roll_BlockFourImagesModule
    ...Roll_BlockHeading
    ...Roll_BlockMarquee
    ...Roll_BlockMediaFrame
    ...Roll_BlockMediaGallery
    ...Roll_BlockMediaText
    ...Roll_BlockMediaTextGallery
    ...Roll_BlockMoodboardImages
    ...Roll_BlockParagraph
    ...Roll_BlockPlayground
    ...Roll_BlockProjectCover
    ...Roll_BlockProjectIntroText
    ...Roll_BlockQuotes
    ...Roll_BlockStats
    ...Roll_BlockTeam
    ...Roll_BlockTechFeatures
    ...Roll_BlockTitleText
  }

`;
