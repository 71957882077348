import { useRef } from "react";

const useComponentWillMount = f => {
  const willMount = useRef(true);

  if (willMount.current) {
    f();
  }

  willMount.current = false;
};

export default useComponentWillMount;
