import React from "react";
import useInView from "use-in-view";

import AnimatedText from "@components/AnimatedText";

import "./style.css";

const Quote = props => {
  const { quote, fadeIn, fadeOut } = props;
  const [ref, isInView] = useInView();

  return (
    <div ref={ref} className="quote">
      {quote && quote.text && (
        <>
          <AnimatedText
            fadeIn={isInView && fadeIn}
            fadeOut={fadeOut}
            text={`“`}
            blockDelay={640}
            duration={800}
          />

          <AnimatedText
            fadeIn={isInView && fadeIn}
            fadeOut={fadeOut}
            text={quote.text}
            delay={240}
            blockDelay={880}
            duration={800}
          />
        </>
      )}

      {quote && quote.author && (
        <AnimatedText
          fadeIn={isInView && fadeIn}
          fadeOut={fadeOut}
          text={
            quote.authorRole
              ? `${quote.author}, ${quote.authorRole}`
              : `${quote.author}`
          }
          blockDelay={1120}
          duration={800}
        />
      )}
    </div>
  );
};

export default Quote;
