import React from "react";
import useInView from "use-in-view";

export default function AnimateLine(props) {
  const [ref, isInView] = useInView();

  return (
    <section className={isInView ? "line-animate" : ""} ref={ref}>
      <svg
        id="arrow-line-desktop-help"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        viewBox="0 0 651 231"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M474.473 1C484.639 8.66667 513.973 24 549.973 24C594.973 24 628.144 27.4637 638.973 41C642.973 46 681.85 111.567 576.973 106.5C461.973 100.944 547.973 -8.5 385.973 116C336.339 154.144 365.768 71.7115 290.473 110.5C240.973 136 188.973 189.5 121.473 118.5C72.1778 66.6491 64.9727 137 56.9727 160.5C45.3982 194.5 19.9727 219 1.97266 227L4.47266 215.5L1.5 228L14.9727 229.5"
          stroke="#EC5D57"
          strokeWidth="2"
        />
      </svg>

      <svg
        id="arrow-line-mobile-help"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeMiterlimit="10"
        viewBox="0 0 191 195"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.99977 2C-2.50037 24 6.99976 51.5 60.9998 65.5C128.5 83 235.238 67.7217 168 102.5C95.5 140 72.8669 160.593 122 155.5C204 147 104 174 86.4998 192L87.5 181.5L85.5 193.5L97.5 191.5"
          stroke="#EC5D57"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </section>
  );
}
