import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Grid from "@components/Grid";
import ParallaxCarousel from "@components/ParallaxCarousel";
import PlaygroundDetail from "@components/PlaygroundDetail";

import "./style.css";

const DEFAULT_HEADER_COLOR = "auto";

const PlaygroundBlock = ({ acf }) => {
  const headerColor = acf?.stats_headerColor ?? DEFAULT_HEADER_COLOR;

  const title = acf?.title ?? "";
  const description = acf?.description ?? "";

  const [activeIndex, setActiveIndex] = useState(-1);

  const items = (acf?.items ?? []).map(item => ({
    title: item?.title ?? "No Title",
    coverTitle: item?.coverTitle ?? "No Title",
    year: item?.year ?? null,
    description: item?.description ?? "No Description",
    link: item?.link ?? null,
    credits: item?.credits ?? null,
    images: (item?.images ?? []).map(({ image }) => ({
      url: image?.mediaItemUrl ?? "",
      width: image?.mediaDetails?.width ?? 0,
      height: image?.mediaDetails?.height ?? 0,
    })),
  }));

  const handleImageClick = useCallback(target => {
    const { top, bottom, right, left } = target.getBoundingClientRect();
    const width = target.width;
    const height = target.height;
    const src = target.src;

    const index = target.dataset.index;

    console.log("handleImageClick", {
      top,
      bottom,
      right,
      left,
      width,
      height,
      src,
      index,
    });

    // remove id
    const previousActiveItem = document.getElementById(
      "playground-detail-active-item"
    );

    if (previousActiveItem) {
      previousActiveItem.removeAttribute("id");
    }

    target.setAttribute("id", "playground-detail-active-item");

    setActiveIndex(index % items.length);
  }, []);

  const handleDismiss = useCallback(() => {
    setActiveIndex(-1);
  }, []);

  if (!items.length) {
    return null;
  }

  console.log("PlaygroundBlock", {
    items,
    images: items.map(item => (item.images.length ? item.images[0].url : "")),
  });

  return (
    <div
      className={classnames("playground-block", `header-color--${headerColor}`)}
    >
      <Grid.Container narrowMargins>
        <Grid.Row>
          <Grid.Cell
            mobileColumns={4}
            columns={8}
            offset={2}
            className="playground-block__intro"
          >
            {title && <h2 className="playground-block__title">{title}</h2>}
            {description && (
              <div
                className="playground-block__description"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>

      <ParallaxCarousel
        images={items.map(item => ({
          url: item.images.length ? item.images[0].url : "",
          title: item.coverTitle,
          year: item.year,
        }))}
        autoScroll={false}
        onImageClick={handleImageClick}
      />

      <PlaygroundDetail
        items={items}
        activeIndex={activeIndex}
        onDismiss={handleDismiss}
      />
    </div>
  );
};

PlaygroundBlock.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default PlaygroundBlock;
