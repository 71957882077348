import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Grid from "@components/Grid";
import Quote from "@components/Quote";

import mod from "@utils/mod";

import "./style.css";

const DEFAULT_TEXT_COLOR = "white";
const DEFAULT_BACKGROUND_COLOR = "black";

const DEFAULT_HEADER_COLOR = "auto";
const DEFAULT_MARGIN_SETTING = "none";

class Quotes extends Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.quotesInnerRef = React.createRef();
    this.progressCursorRef = React.createRef();

    this.pointerInitialX = -1;

    this.state = {
      index: 0,
      unclampedIndex: 0,
      startTime: Date.now(),
    };

    //in milliseconds
    this.duration = 6000;
  }

  updateIndex = () => {
    const { unclampedIndex } = this.state;
    let { quotes, acf } = this.props;

    if (acf && acf.quotes) {
      quotes = acf.quotes;
    }

    if (quotes.length < 2) {
      return;
    }

    const newUnclampedIndex = unclampedIndex + 1;
    const newIndex = mod(newUnclampedIndex, quotes.length);

    this.setState({
      index: newIndex,
      unclampedIndex: newUnclampedIndex,
      startTime: Date.now(),
    });
  };

  handleClick = () => {
    clearInterval(this.quoteUpdatelInterval);
    this.updateIndex();
    this.startUpdateQuotes();
  };
  handlePointerDown = e => {
    clearInterval(this.quoteUpdatelInterval);

    this.pointerInitialX =
      e.type === "touchstart" ? e.touches[0].clientX : e.clientX;

    this.progressCursorRef.current.style.transition = "none";
  };
  handlePointerMove = e => {
    if (this.pointerInitialX < 0) {
      return;
    }

    const { unclampedIndex } = this.state;
    let { quotes, acf } = this.props;

    if (acf && acf.quotes) {
      quotes = acf.quotes;
    }

    const pointerCurrentX =
      e.type === "touchmove" ? e.touches[0].clientX : e.clientX;

    const diff = (pointerCurrentX - this.pointerInitialX) / quotes.length;

    this.progressCursorRef.current.style.backgroundPositionX = `${(unclampedIndex /
      quotes.length) *
      this.progressCursorRef.current.offsetWidth -
      diff}px`;
  };
  handlePointerUp = e => {
    this.progressCursorRef.current.style.transition = null;

    const { unclampedIndex } = this.state;
    let { quotes, acf } = this.props;

    if (acf && acf.quotes) {
      quotes = acf.quotes;
    }

    if (quotes.length < 2) {
      this.pointerInitialX = -1;
      return;
    }

    this.startUpdateQuotes();

    if (e.type === "touchcancel") {
      this.pointerInitialX = -1;
      return;
    }

    const lastPointerX =
      e.type === "touchend" ? e.changedTouches[0].clientX : e.clientX;

    const pointerDelta = lastPointerX - this.pointerInitialX;

    this.pointerInitialX = -1;

    const newUnclampedIndex =
      pointerDelta > 0 ? unclampedIndex - 1 : unclampedIndex + 1;
    const newIndex = mod(newUnclampedIndex, quotes.length);

    this.setState({
      index: newIndex,
      unclampedIndex: newUnclampedIndex,
      startTime: Date.now(),
    });
  };

  handleResize = () => {
    // get highest quote
    const quotes = this.quotesInnerRef.current.querySelectorAll(".quote");
    let highestQuote = 0;
    quotes.forEach(quote => {
      const height = quote.offsetHeight;
      if (height > highestQuote) {
        highestQuote = height;
      }
    });

    if (highestQuote) {
      // set height of quotes container
      this.quotesInnerRef.current.style.height = `${highestQuote}px`;
    }
  };

  startUpdateQuotes = () => {
    this.quoteUpdatelInterval = setInterval(this.updateIndex, this.duration);
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    document.fonts.ready.then(this.handleResize);

    this.startUpdateQuotes();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);

    clearInterval(this.quoteUpdatelInterval);
  }

  render() {
    const { unclampedIndex, index, startTime } = this.state;
    const { acf } = this.props;

    const quotes = acf?.quotes ?? [];

    const styles = {
      color: acf?.quotes_textColor ?? DEFAULT_TEXT_COLOR,
      background: acf?.quotes_backgroundColor ?? DEFAULT_BACKGROUND_COLOR,
    };

    const headerColor = acf?.quotes_headerColor ?? DEFAULT_HEADER_COLOR;
    const marginTop = acf?.quotes_marginTop ?? DEFAULT_MARGIN_SETTING;
    const marginBottom = acf?.quotes_marginBottom ?? DEFAULT_MARGIN_SETTING;

    return (
      <div
        className={classnames(
          "quotes",
          `header-color--${headerColor}`,
          `quotes--margin-top-${marginTop}`,
          `quotes--margin-bottom-${marginBottom}`
        )}
        style={styles}
        data-index={index}
        data-count={quotes.length || 0}
        data-duration={this.duration}
        data-start-time={startTime}
        // onClick={this.handleClick}
        onTouchStart={this.handlePointerDown}
        onTouchMove={this.handlePointerMove}
        onTouchEnd={this.handlePointerUp}
        onTouchCancel={this.handlePointerUp}
        onMouseDown={this.handlePointerDown}
        onMouseMove={this.handlePointerMove}
        onMouseUp={this.handlePointerUp}
        role="presentation"
      >
        <Grid.Container narrowMargins>
          <Grid.Row>
            <Grid.Cell mobileColumns={4} columns={12} offset={0}>
              <div ref={this.quotesInnerRef} className="quotes__inner">
                {quotes.map((quote, i) => (
                  <Quote
                    key={i}
                    quote={quote}
                    fadeIn={
                      (index === i || mod(index - 1, quotes.length) === i) &&
                      this.props.entered
                    }
                    fadeOut={
                      mod(index - 1, quotes.length) === i && quotes.length > 1
                    }
                  />
                ))}
              </div>

              <div className="quotes__progress">
                <div
                  ref={this.progressCursorRef}
                  className="quotes__progress-cursor"
                  style={{
                    "--width": `${(1 / quotes.length) * 100}%`,
                    backgroundPositionX: `${(unclampedIndex / quotes.length) *
                      (this.progressCursorRef.current
                        ? this.progressCursorRef.current.offsetWidth
                        : 0)}px`,
                  }}
                />
              </div>
            </Grid.Cell>
          </Grid.Row>
        </Grid.Container>
      </div>
    );
  }
}

Quotes.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default Quotes;
