import React, { forwardRef, useEffect, useMemo, createRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./style.css";

const DEFAULT_IE_OBJECT_FIT = "cover";

const noop = event => event.preventDefault();

const Video = forwardRef(
  (
    {
      className,
      objectFit,
      objectPosition,
      autoPlayInView,
      setPlaying,
      ...props
    },
    ref
  ) => {
    const video = useMemo(() => ref || createRef(null), [ref]);

    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        if (entries.length > 0) {
          if (entries[0].isIntersecting) {
            video.current
              .play()
              .then(() => setPlaying(true))
              .catch(() =>
                console.log("Video not started. Waiting user interaction.")
              );
          } else {
            video.current.pause();
            setPlaying(false);
          }
        }
      });

      observer.observe(video.current);

      return () => observer.disconnect();
    }, [video, setPlaying]);

    return (
      <video
        className={classnames("custom-video", className)}
        data-object-fit={objectFit}
        data-object-position={objectPosition}
        draggable="false"
        onDragStart={noop}
        playsInline
        muted={autoPlayInView}
        ref={video}
        {...props}
      >
        <track src="" kind="captions" />
      </video>
    );
  }
);

Video.propTypes = {
  className: PropTypes.string,
  objectFit: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  objectPosition: PropTypes.string,
  setPlaying: PropTypes.func,
};
Video.defaultProps = {
  objectFit: DEFAULT_IE_OBJECT_FIT,
  setPlaying: () => null,
};

export default Video;
