import React from "react";
import PropTypes from "prop-types";
import ServicesAnimatedText from "@components/ServicesAnimatedText";

const Marquee = ({ acf }) => {
  const text = acf?.text ?? "";

  return <ServicesAnimatedText services={text} />;
};

Marquee.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default Marquee;
