import React from "react";
import { useThree, extend } from "react-three-fiber";
import { OrbitControls as THREEOrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls: THREEOrbitControls });

const OrbitControls = () => {
  const { camera, gl } = useThree();

  return <orbitControls args={[camera, gl.domElement]} enabled={true} />;
};

export default OrbitControls;
