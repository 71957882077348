// NOTE: here an iPad in portrait (768w) goes in the `mobile` viewport
const tablet = 840;
const laptop = 1025;

module.exports = {
  tablet,
  laptop,
  customMedia: {
    "--tablet": `(min-width: ${tablet}px)`,
    "--laptop": `(min-width: ${laptop}px)`,
  },
};
