import classnames from "classnames";
import React, {
  cloneElement,
  Component,
  createRef,
  isValidElement,
} from "react";

import "./style.css";

class AnimatedText extends Component {
  constructor(props) {
    super(props);

    this.ref = props.innerRef || createRef();
    this.animatedTextTesterRef = createRef();

    this.state = {
      mounted: false,
      rows: [],
    };
  }

  static defaultProps = {
    delay: 16,
    duration: 800,
    easing: "cubic-bezier(0.23, 1, 0.32, 1)",
    blockDelay: 0,
    onFadedIn: f => f,
  };

  getRawText = text => {
    let rawText = "";
    const remainingParts = [text];

    while (remainingParts.length) {
      const part = remainingParts.shift();
      if (typeof part === "string") {
        rawText += part;
      } else if (isValidElement(part)) {
        if (Array.isArray(part.props.children)) {
          remainingParts.unshift(...part.props.children);
        } else {
          remainingParts.unshift(part.props.children);
        }
      }
    }

    return rawText;
  };

  getSubString = (text, startFrom = 0, endBefore) => {
    if (endBefore && startFrom >= endBefore) {
      // substring parameters not valid
      return "";
    }

    if (typeof text === "string") {
      return text.substring(startFrom, endBefore);
    }

    if (!isValidElement(text)) {
      // that's not a string and not a valid react element, what is that? 👽
      return false;
    }

    let index = 0;
    const newChildren = [];
    const remainingParts = [];

    if (Array.isArray(text.props.children)) {
      remainingParts.push(...text.props.children);
    } else {
      // it's a string only
      remainingParts.push(text.props.children);
    }

    while (remainingParts.length) {
      const part = remainingParts.shift();

      if (typeof part === "string") {
        if (
          index + part.length > startFrom &&
          (!endBefore || index < endBefore)
        ) {
          // with this string we go ahead of the `startFrom` index
          // put the string back into the children list but as substring
          const substring = part.substring(
            Math.max(startFrom - index, 0),
            endBefore ? Math.min(part.length, endBefore - index) : part.length
          );
          newChildren.push(substring);

          // update index so we can break while cycle
          index += Math.max(startFrom - index, 0) + substring.length;
        } else {
          // this string falls before the `startFrom` or after `endBefore`
          // indexes, so we can trash it.
          // update index with this string length
          index += part.length;
        }
      } else if (isValidElement(part)) {
        if (index > 0 && endBefore && part.type === "br") {
          // break line here
          break;
        }
        // it's a component, but we can't divide it, so we rip it off
        // update index with this component string length
        const partLength = this.getRawText(part).length;

        if (
          (!endBefore || index + partLength <= endBefore) &&
          index >= startFrom
        ) {
          newChildren.push(part);
        }

        index += partLength;
      }
    }

    // return a new react element cloning text and replacing children
    return cloneElement(text, {}, newChildren);
  };

  getRow = text => {
    const animatedTextTester = this.animatedTextTesterRef.current;
    let newText = text;
    let rawText = this.getRawText(newText);
    animatedTextTester.textContent = rawText;

    while (animatedTextTester.scrollWidth > animatedTextTester.offsetWidth) {
      let lastIndex = rawText.lastIndexOf(" ");
      newText = this.getSubString(newText, 0, lastIndex);
      rawText = this.getRawText(newText);
      animatedTextTester.textContent = rawText;
    }

    return newText;
  };

  getRows = () => {
    const { text } = this.props;
    let textRows = [];
    let newText = text;

    while (this.getRawText(newText).length > 0) {
      const newRow = this.getRow(newText);
      const newRowLength = this.getRawText(newRow).length;
      textRows.push(newRow);

      newText = this.getSubString(newText, newRowLength);
    }

    this.setState({
      rows: textRows,
    });
  };

  onFadedOut = () => {
    this.animatedTextTesterRef.current.classList.remove("fade-out");
    this.animatedTextTesterRef.current.classList.add("hidden");
  };

  onTransitionEnd = () => {
    if (
      this.animatedTextTesterRef.current &&
      this.animatedTextTesterRef.current.classList.contains("fade-out")
    ) {
      this.onFadedOut();
    }
  };

  onWindowResize = () => {
    this.resizeTimeout && clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.getRows();
    }, 100);
  };

  componentDidMount() {
    this.getRows();

    this.timer = setTimeout(() => {
      this.setState({
        mounted: true,
      });
    }, 20);

    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.fadedInTimer) {
      clearTimeout(this.fadedInTimer);
    }

    window.removeEventListener("resize", this.onWindowResize);
  }

  componentWillUpdate(nextProps, nextState) {
    const { fadeIn, fadeOut, onFadedIn, delay, blockDelay } = nextProps;
    const { mounted, rows } = nextState;

    const prev = this.state.mounted && this.props.fadeIn && !this.props.fadeOut;

    if (!this.fadedInTimer && !prev && mounted && fadeIn && !fadeOut) {
      this.fadedInTimer = setTimeout(() => {
        onFadedIn();
        this.fadedInTimer = null;
      }, rows.length * delay + blockDelay);
    }
  }

  render() {
    const {
      fadeIn,
      fadeOut,
      delay,
      duration,
      easing,
      blockDelay,
      className,
    } = this.props;

    const { mounted, rows } = this.state;

    return (
      <div
        ref={this.ref}
        className={classnames(
          "animated-text",
          {
            hidden: (mounted && !fadeIn) || !mounted || !fadeIn,
            "fade-out": mounted && fadeIn && fadeOut,
          },
          className
        )}
        style={{
          transitionDelay: `${blockDelay}ms`,
          transitionDuration: `${blockDelay +
            duration +
            delay * (rows.length - 1)}ms`,
        }}
        onTransitionEnd={this.onTransitionEnd}
      >
        <div
          ref={this.animatedTextTesterRef}
          className="animated-text__tester"
        />
        {rows.map((row, i) => (
          <span
            key={`row--${i}`}
            style={{
              transitionDelay: `${i * delay + blockDelay}ms`,
              transitionDuration: `${duration}ms`,
              transitionTimingFunction: easing,
            }}
          >
            {row}
          </span>
        ))}
      </div>
    );
  }
}

export default AnimatedText;
