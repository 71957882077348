import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import classnames from "classnames";

import "./style.css";

const Context = React.createContext(0);

const SLIDE_CHANGE_WAIT = 1500;

export const Slide = ({ children, index }) => {
  const currentIndex = useContext(Context);
  return children(index === currentIndex);
};

export const Wrapper = ({ children: slides, className }) => {
  const touchY = useRef([0, 0]);
  const lastSlideChangeTime = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleWheel = useCallback(
    e => {
      if (document.documentElement.scrollTop > 0) {
        return;
      }
      if (e.type === "touchstart") {
        touchY.current[0] = e.touches[0].clientY;
        touchY.current[1] = e.touches[0].clientY;
        return;
      }
      if (e.type === "touchmove") {
        touchY.current[1] = e.touches[0].clientY;
        return;
      }

      const now = new Date().getTime();
      if (
        lastSlideChangeTime.current &&
        now - lastSlideChangeTime.current < SLIDE_CHANGE_WAIT
      ) {
        return;
      }

      let deltaY = 0;
      if (e.type === "touchend") {
        deltaY = touchY.current[0] - touchY.current[1];
        if (Math.abs(deltaY) < 10) {
          return;
        }
      }
      if (e.type === "wheel") {
        deltaY = e.deltaY;
      }

      const direction = deltaY > 0 ? 1 : -1;

      const newIndex = currentIndex + direction;
      if (newIndex >= 0 && newIndex < slides.length) {
        // console.log("slide change", newIndex, {
        //   lastSlideChangeTime: lastSlideChangeTime.current,
        //   now,
        // });
        setCurrentIndex(newIndex);
        lastSlideChangeTime.current = now;
      } else if (
        newIndex === -1 &&
        !document.body.classList.contains("no-scroll")
      ) {
        document.body.classList.add("no-scroll");
      } else if (
        newIndex === slides.length &&
        document.body.classList.contains("no-scroll")
      ) {
        document.body.classList.remove("no-scroll");
      }
    },
    [currentIndex, setCurrentIndex, slides]
  );

  useEffect(() => {
    if (
      document.documentElement.scrollTop === 0 &&
      !document.body.classList.contains("no-scroll") &&
      currentIndex >= 0 &&
      currentIndex < slides.length - 1
    ) {
      document.body.classList.add("no-scroll");
    }
  }, [currentIndex, slides]);

  useEffect(() => {
    setCurrentIndex(document.documentElement.scrollTop > 0 ? 1 : 0);

    return () => {
      if (document.body.classList.contains("no-scroll")) {
        document.body.classList.remove("no-scroll");
      }
    };
  }, []);

  return (
    <Context.Provider value={currentIndex}>
      <div
        className={classnames("section-slides", className)}
        onTouchStart={handleWheel}
        onTouchMove={handleWheel}
        onTouchEnd={handleWheel}
        onWheel={handleWheel}
      >
        {slides.map((slide, index) =>
          React.cloneElement(slide, { key: `slide--${index}`, index })
        )}
      </div>
    </Context.Provider>
  );
};

Wrapper.propTypes = {
  children: function(props, propName, componentName) {
    const prop = props[propName];

    let error = null;
    React.Children.forEach(prop, function(child) {
      if (child.type !== Slide) {
        error = new Error(
          "`" + componentName + "` children should be of type `Slide`."
        );
      }
    });
    return error;
  },
};
