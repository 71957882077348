import gsap, { Power2 } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import { Link as SimpleLink } from "gatsby";
import React, { useEffect, useState } from "react";
import { isStorybook } from "@utils/isEnvironment";

import "./style.css";

const MorphLink = ({ children, to, duration, delay = 0, morph, ...props }) => {
  const [isStorybookMode, setIsStorybookMode] = useState(false);
  useEffect(() => {
    if (isStorybook()) {
      setIsStorybookMode(true);
    }
  }, [setIsStorybookMode]);
  // check if is in STORYBOOK
  const Link = isStorybookMode ? SimpleLink : TransitionLink;

  return (
    <Link
      to={to}
      exit={{
        length: duration + delay,
      }}
      entry={{
        appearAfter: duration + delay,
      }}
      trigger={async pages => {
        // lock exit scroll
        document.body.classList.add("morph-link-scroll-lock");
        const exit = await pages.exit;
        const entry = await pages.entry;

        const morphFromEl = exit.node.querySelector(morph.from);
        const morphToEl = entry.node.querySelector(morph.to);

        const morphFromBCR = morphFromEl.getBoundingClientRect();
        const morphToBCR = morphToEl.getBoundingClientRect();

        const finalMeasurements = {
          x:
            morphToBCR.left -
            (morphFromBCR.left - document.documentElement.scrollLeft),
          y:
            morphToBCR.top -
            (morphFromBCR.top - document.documentElement.scrollTop),
          height: morphToEl.offsetHeight,
          width: morphToEl.offsetWidth,
          backgroundPosition: "50% 0%",
        };

        gsap.to(morphFromEl, {
          ...finalMeasurements,
          duration,
          delay,
          ease: Power2.easeInOut,
          onComplete: () => {
            // unlock exit scroll
            document.body.classList.remove("morph-link-scroll-lock");
            window.scrollTo(0, 0);
          },
        });
      }}
      preventScrollJump={false}
      {...props}
    >
      {children}
    </Link>
  );
};

export default MorphLink;
