import { graphql } from "gatsby";
import gql from "graphql-tag";
import React from "react";

import { previewFragments } from "@blocks/fragments";
import BlocksRenderer from "@components/BlocksRenderer";
import Footer from "@components/Footer";
import NextProjectCover from "@components/NextProjectCover";
import ProjectCredits from "@components/ProjectCredits";
import getPostData from "@utils/getPostData";
import useUIColorCheck from "@utils/useUIColorCheck";
import Helmet from "react-helmet";
import decodeHtmlEntities from "@utils/decodeHtmlEntities";

import "./style.css";

const ProjectTemplate = props => {
  const { data, preview, transitionStatus } = props;

  useUIColorCheck([transitionStatus === "entered"]);

  const project = getPostData("project", { data, preview });

  const next = data?.wpgraphql?.next?.nodes[0] || preview?.next;

  if (next?.blocksPreview) {
    next.blocks = next.blocksPreview;
  }

  const pageTitle = decodeHtmlEntities(
    data?.wpgraphql?.project.metaTagsAcf.pageTitle
      ? data?.wpgraphql?.project.metaTagsAcf.pageTitle +
          " | " +
          data?.site?.siteMetadata.title
      : data?.wpgraphql?.project.title
  );
  const pageDescription =
    data?.wpgraphql?.project?.metaTagsAcf?.pageDescription;
  const pageImage =
    data?.wpgraphql?.project?.metaTagsAcf?.pageImage?.mediaItemUrl;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {pageImage && <meta property="og:image" content={pageImage} />}
      </Helmet>
      <div className="project-page">
        <BlocksRenderer
          blocks={project.blocks}
          entered={transitionStatus === "entered"}
          entering={transitionStatus === "entering"}
        />

        {project?.acf?.credits && (
          <ProjectCredits credits={project.acf.credits} />
        )}

        {next && (
          <NextProjectCover
            exiting={transitionStatus === "exiting"}
            project={next}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($wpId: ID!, $cursor: String) {
    site {
      siteMetadata {
        title
      }
    }
    wpgraphql {
      project(id: $wpId, idType: DATABASE_ID) {
        uri
        title
        status
        slug
        content

        acf {
          credits {
            category
            names {
              name
            }
          }
        }

        metaTagsAcf {
          pageTitle
          pageDescription
          pageImage {
            mediaItemUrl
          }
        }

        blocks {
          ...Roll_Block
        }
      }

      next: projects(after: $cursor, first: 1) {
        nodes {
          uri
          blocks {
            __typename
            ...Roll_BlockProjectCover
          }
        }
      }
    }
  }
`;

export const previewQuery = gql`
  query($wpId: ID!, $nextId: ID!) {
    wpgraphql {
      project(id: $wpId, idType: DATABASE_ID) {
        blocks {
          ...Roll_Block
        }
        blocksPreview {
          ...Roll_Block
        }

        preview: revisions(first: 1, before: null) {
          nodes {
            uri
            title
            status
            slug
            content

            acf {
              credits {
                category
                names {
                  name
                }
              }
            }
          }
        }
      }

      next: project(id: $nextId, idType: DATABASE_ID) {
        uri
        blocks {
          __typename
          ...Roll_BlockProjectCover
        }

        blocksPreview {
          __typename
          ...Roll_BlockProjectCover
        }
      }
    }
  }

  ${previewFragments}
`;

export default ProjectTemplate;
