import PropTypes from "prop-types";
import React from "react";
import { useFrame } from "react-three-fiber";
import { usePlane } from "use-cannon";
import { useVisibleSizeAtZDepth } from "@utils/visibleSizeAtZDepth";

const DAMPING_FACTOR = 0.01;
const edgePadding = 0;

const PlaneConstraint = props => {
  const {
    position,
    rotation,
    magnet,
    opacity,
    wireframe,
    color,
    fixedPosition: isFixedPosition,
    ...rest
  } = props;
  const [ref, api] = usePlane(() => ({
    mass: 0,
    position,
    rotation,
  }));
  const { width, height } = useVisibleSizeAtZDepth(0);

  useFrame(() => {
    const [currentX, currentY, currentZ] = ref.current.position.toArray();
    let [targetX, targetY, targetZ] = position;

    if (isFixedPosition) {
      api.position.set(targetX, targetY, targetZ);
      return;
    }

    if (magnet) {
      switch (magnet) {
        case "bottom":
          targetY = -(height / 2 - edgePadding);
          break;
        case "right":
          targetX = width / 2 - edgePadding;
          break;
        case "top":
          targetY = height / 2 - edgePadding;
          break;
        case "left":
          targetX = -(width / 2 - edgePadding);
          break;

        default:
          break;
      }
    }

    api.position.set(
      currentX + (targetX - currentX) * DAMPING_FACTOR,
      currentY + (targetY - currentY) * DAMPING_FACTOR,
      currentZ + (targetZ - currentZ) * DAMPING_FACTOR
    );
  });

  return (
    <mesh ref={ref} {...rest}>
      <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
      <meshBasicMaterial
        attach="material"
        opacity={opacity}
        wireframe={wireframe}
        transparent
        color={color}
      />
    </mesh>
  );
};

PlaneConstraint.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number),
  rotation: PropTypes.arrayOf(PropTypes.number),
  size: PropTypes.number,
  opacity: PropTypes.number,
  wireframe: PropTypes.bool,
  color: PropTypes.string,
  magnet: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};
PlaneConstraint.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  size: 1000,
  opacity: 0,
  wireframe: false,
  color: "black",
};

export default PlaneConstraint;
