import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const members = useStaticQuery(graphql`
    {
      wpgraphql {
        teamMembers {
          edges {
            node {
              title
              acf {
                role
                socials {
                  link
                  social
                }
                faces {
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return [...members?.wpgraphql?.teamMembers?.edges];
};
