import { useRef, useMemo } from "react";
import { Vector3, Box3 } from "three";

const useGLTFSize = (gltf, scale) => {
  const b3 = useRef(new Box3());
  const v3 = useRef(new Vector3());

  // Get size when gltf object is loaded
  return useMemo(() => {
    if (!gltf) {
      return null;
    }

    if (!gltf.scene.children.length) {
      for (const key in gltf.nodes) {
        if (gltf.nodes.hasOwnProperty(key)) {
          const n = gltf.nodes[key];
          if (n.type === "Mesh") {
            gltf.scene.add(n);
          }
        }
      }
    }

    // Get group size
    gltf.scene.scale.set(scale, scale, scale);
    b3.current.setFromObject(gltf.scene);
    b3.current.getSize(v3.current);

    return v3.current.toArray();
  }, [gltf, scale]);
};

export default useGLTFSize;
