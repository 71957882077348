import React, { useContext } from "react";
import Grid from "@components/Grid";
import ModalContext from "@components/ModalContext";

import PropTypes from "prop-types";
import classnames from "classnames";

import AnimatedTextOnView from "@components/AnimatedTextOnView";
import Container from "@components/Container";
import FadeInOnView from "@components/FadeInOnView";
import Image from "@components/Image";
import Video from "@components/Video";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";

const DEFAULT_TEXT_COLOR = "black";
const DEFAULT_BACKGROUND_COLOR = "white";

const DEFAULT_TEXT_POSITION = "start";
const DEFAULT_TEXT_ALIGNMENT = "left";

const DEFAULT_MARGIN_SETTING = "normal";

const DEFAULT_HEADER_COLOR = "auto";

const MediaText = ({ acf, template }) => {
  const modal = useContext(ModalContext);

  const openModalBusiness = () => {
    modal.setIsOpen({ isOpen: true, typeForm: "business" });
  };

  const title = acf?.title ?? "";
  const text = acf?.text ?? "";

  const reversed = acf?.reversed ?? false;

  const mediaUrl = acf?.media?.mediaItemUrl ?? "";
  const mediaType = acf?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  let textPosition = acf?.textPosition ?? DEFAULT_TEXT_POSITION;
  if (textPosition !== "center") {
    textPosition = `flex-${textPosition}`;
  }
  const textAlignment = acf?.textAlignment ?? DEFAULT_TEXT_ALIGNMENT;

  const textColor = acf?.mediaText_textColor ?? DEFAULT_TEXT_COLOR;
  const backgroundColor =
    acf?.mediaText_backgroundColor ?? DEFAULT_BACKGROUND_COLOR;

  const Media = mediaType === DEFAULT_MEDIA_TYPE ? Image : Video;

  const isInAboutPage = template === "about" ? true : false;

  const classes = classnames(
    isInAboutPage ? "is-about-page" : "",
    "media-text",
    `margin-top--${acf?.mediaText_marginTop ?? DEFAULT_MARGIN_SETTING}`,
    `margin-bottom--${acf?.mediaText_marginBottom ?? DEFAULT_MARGIN_SETTING}`,
    {
      "media-text--reversed": reversed,
    },
    `header-color--${acf?.mediaText_headerColor ?? DEFAULT_HEADER_COLOR}`
  );

  return isInAboutPage ? (
    <div className={classes} style={{ backgroundColor: backgroundColor }}>
      <Grid.Container fluid>
        <Grid.Row>
          <Grid.Cell mobileColumns={4} columns={6} offset={0} className="cell">
            <FadeInOnView className="media-text__media" translate="medium">
              <Media
                src={mediaUrl}
                objectPosition="center"
                muted
                autoPlay
                loop
              />
            </FadeInOnView>
          </Grid.Cell>

          <Grid.Cell mobileColumns={4} columns={6} offset={0} className="cell">
            <div
              className="media-text__text"
              style={{
                color: textColor,
                justifyContent: textPosition,
                textAlign: textAlignment,
              }}
            >
              <h2>
                <AnimatedTextOnView text={title} />
              </h2>
              <FadeInOnView>
                <div dangerouslySetInnerHTML={{ __html: text }} />
                {isInAboutPage && (
                  <button
                    onClick={openModalBusiness}
                    className="cta-start-project"
                  >
                    Start a project
                  </button>
                )}
              </FadeInOnView>
            </div>
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>
    </div>
  ) : (
    <div className={classes} style={{ backgroundColor: backgroundColor }}>
      <Container variant="small">
        <div
          className="media-text__text"
          style={{
            color: textColor,
            justifyContent: textPosition,
            textAlign: textAlignment,
          }}
        >
          <h2>
            <AnimatedTextOnView text={title} />
          </h2>
          <FadeInOnView>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </FadeInOnView>
        </div>
        <FadeInOnView className="media-text__media" translate="medium">
          <Media src={mediaUrl} objectPosition="center" muted autoPlay loop />
        </FadeInOnView>
      </Container>
    </div>
  );
};

MediaText.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default MediaText;
