import PropTypes from "prop-types";
import { useFrame } from "react-three-fiber";
import { Vector3 } from "three";

const DAMPING_FACTOR = 0.01;
const LOOK_POSITION = new Vector3(0, 0, 0);

const CameraOperator = ({ position }) => {
  useFrame(({ camera }) => {
    // apply damping and set position
    camera.position.x += (position.x - camera.position.x) * DAMPING_FACTOR;
    camera.position.y += (position.y - camera.position.y) * DAMPING_FACTOR;
    camera.position.z += (position.z - camera.position.z) * DAMPING_FACTOR;

    // update look position
    camera.lookAt(LOOK_POSITION);
  });

  return null;
};

CameraOperator.propTypes = {
  position: PropTypes.object,
};

export default CameraOperator;
