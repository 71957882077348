import PropTypes from "prop-types";
import React, { useRef, useEffect, useCallback } from "react";
import { useFrame } from "react-three-fiber";

import useMountEffect from "@utils/useMountEffect";

const ACTIVE_PROJECT_OPACITY = 0.75;
const OPACITY_ACTIVE_DUMPING_FACTOR = 0.05;
const OPACITY_UNACTIVE_DUMPING_FACTOR = 0.25;

const Overlay = ({
  active: isActive,
  color,
  onPointerOver,
  onPointerMove,
  onPointerOut,
  ...props
}) => {
  const ref = useRef();

  const handlePointerOver = useCallback(
    event => {
      event.stopPropagation();
      onPointerOver();
    },
    [onPointerOver]
  );

  const handlePointerMove = useCallback(
    event => {
      event.stopPropagation();
      onPointerMove();
    },
    [onPointerMove]
  );

  const handlePointerOut = useCallback(
    event => {
      event.stopPropagation();
      onPointerOut();
    },
    [onPointerOut]
  );

  useEffect(() => {
    if (!isActive) {
      onPointerOut();
    }
  }, [isActive, onPointerOut]);

  useMountEffect(() => {
    ref.current.material.opacity = isActive ? ACTIVE_PROJECT_OPACITY : 0;
  });

  useFrame(() => {
    // Apply variation on opacity
    const targetOpacity = isActive ? ACTIVE_PROJECT_OPACITY : 0;
    ref.current.material.opacity +=
      (targetOpacity - ref.current.material.opacity) *
      (isActive
        ? OPACITY_ACTIVE_DUMPING_FACTOR
        : OPACITY_UNACTIVE_DUMPING_FACTOR);
  });

  return (
    <mesh
      ref={ref}
      onPointerOver={isActive ? handlePointerOver : undefined}
      onPointerMove={isActive ? handlePointerMove : undefined}
      onPointerOut={isActive ? handlePointerOut : undefined}
      {...props}
    >
      <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
      <meshBasicMaterial
        attach="material"
        depthTest={true}
        color={color}
        transparent
      />
    </mesh>
  );
};

Overlay.propTypes = {
  active: PropTypes.bool,
  opacity: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onPointerOver: PropTypes.func,
  onPointerOut: PropTypes.func,
};
Overlay.defaultProps = {
  active: false,
  color: "#000000",
  onPointerOver: f => f,
  onPointerOut: f => f,
};

export default Overlay;
