import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./style.css";

const DEFAULT_IE_OBJECT_FIT = "cover";

const noop = event => event.preventDefault();

const Image = forwardRef(
  (
    {
      className,
      objectFit,
      objectPosition,
      autoPlayInView: _,
      setPlaying: __,
      ...props
    },
    ref
  ) => (
    <img
      ref={ref}
      className={classnames("custom-img", className)}
      data-object-fit={objectFit}
      data-object-position={objectPosition}
      alt=""
      draggable="false"
      onDragStart={noop}
      role="presentation"
      lazy="true"
      {...props}
    />
  )
);

Image.propTypes = {
  className: PropTypes.string,
  objectFit: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  objectPosition: PropTypes.string,
};
Image.defaultProps = {
  objectFit: DEFAULT_IE_OBJECT_FIT,
};

export default Image;
