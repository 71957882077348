import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import { useFrame } from "react-three-fiber";
import StatsJs from "stats.js";

const Stats = ({ onReady, onDestroy }) => {
  const stats = useRef(null);

  useEffect(() => {
    stats.current = new StatsJs();
    stats.current.showPanel(0);

    onReady(stats.current.dom);

    return () => {
      onDestroy();
      stats.current.dom.remove();
    };
  }, [onReady, onDestroy]);

  useFrame(() => {
    stats.current.update();
  });

  return null;
};

Stats.propTypes = {
  onReady: PropTypes.func,
  onDestroy: PropTypes.func,
};
Stats.defaultProps = {
  onReady: f => f,
  onDestroy: f => f,
};

export default Stats;
