import { navigate } from "gatsby";
import PropTypes from "prop-types";
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useFrame, useThree } from "react-three-fiber";
import { Physics } from "use-cannon";

import PlaneConstraint from "@components/PlaneConstraint";

import Overlay from "./Overlay";
import Shape from "./Shape";
import { useConfig } from "./configs";

const ShapesBox = ({
  shapes,
  backgroundColor,
  paused = false,
  active: isActive,
  firstVisit: isFirstVisit,
  onShapePointerOver,
  onShapePointerMove,
  onShapePointerOut,
  onOverlayPointerOver,
  onOverlayPointerMove,
  onOverlayPointerOut,
  onOpenShapeIdChange,
}) => {
  const shapesGroup = useRef();
  const openStartTime = useRef();
  const [openShapeId, setOpenShapeId] = useState(null);
  const [isInOpenPosition, setIsInOpenPosition] = useState(false);
  const { wireframe: _, ...contactMaterial } = useConfig(
    config => config.contactMaterial
  );

  useEffect(() => {
    setIsInOpenPosition(false);
    openStartTime.current = null;
  }, [openShapeId]);

  const { camera } = useThree();

  const fov = useMemo(() => (camera.fov * Math.PI) / 180, [camera.fov]);

  useFrame(({ gl, scene, camera, clock }) => {
    if (!paused) {
      if (!!openShapeId) {
        if (!openStartTime.current) {
          openStartTime.current = clock.getElapsedTime();
        }

        if (
          !isInOpenPosition &&
          clock.getElapsedTime() - openStartTime.current > 0.7
        ) {
          setIsInOpenPosition(true);
          onOpenShapeIdChange(openShapeId);
        }
      }

      // if (shapesGroup.current) {
      //   if (clock.getElapsedTime() > 5) {
      //     shapesGroup.current.rotateZ(-0.0015);
      //   }
      // }
      gl.render(scene, camera);
    }
  }, 1);

  const handleProjectPointerUp = useCallback(
    ({ id }) => {
      if (!openShapeId) {
        setOpenShapeId(id);
      } else if (openShapeId === id) {
        const { url } = shapes.find(p => p.id === id);
        if (!!url) {
          navigate(url);
        }
      }
    },
    [openShapeId, setOpenShapeId, shapes]
  );
  const handleOverlayPointerDown = useCallback(
    event => {
      if (openShapeId) {
        event.stopPropagation();
      }
    },
    [openShapeId]
  );
  const handleOverlayPointerUp = useCallback(
    event => {
      if (openShapeId) {
        event.stopPropagation();
        setOpenShapeId(null);
        onOpenShapeIdChange(null);
      }
    },
    [openShapeId, setOpenShapeId, onOpenShapeIdChange]
  );

  return (
    <>
      <Physics
        defaultContactMaterial={contactMaterial}
        gravity={[0, 0, 0]}
        size={100}
        allowSleep={false}
      >
        {/* left plane */}
        <PlaneConstraint
          name="left-plane"
          magnet="left"
          opacity={0}
          wireframe={false}
          position={[-1440, 0, 0]}
          fixedPosition={!isActive || paused}
          rotation={[0, Math.PI / 2 + fov / 2, 0]}
        />

        {/* right plane */}
        <PlaneConstraint
          name="right-plane"
          magnet="right"
          opacity={0}
          wireframe={false}
          position={[1440, 0, 0]}
          fixedPosition={!isActive || paused}
          rotation={[0, -Math.PI / 2 - fov / 2, 0]}
        />

        {/* top plane */}
        <PlaneConstraint
          name="top-plane"
          magnet="top"
          opacity={0}
          wireframe={false}
          position={[0, 1440, 0]}
          fixedPosition={!isActive || paused}
          rotation={[Math.PI / 2 + fov / 2, 0, 0]}
        />

        {/* bottom plane */}
        <PlaneConstraint
          name="bottom-plane"
          magnet="bottom"
          opacity={0}
          wireframe={false}
          position={[0, -1440, 0]}
          fixedPosition={!isActive || paused}
          rotation={[-Math.PI / 2 - fov / 2, 0, 0]}
        />

        <group ref={shapesGroup}>
          {shapes.map(({ id, glb, scale }, i) => (
            <Shape
              key={`project--${id}`}
              id={id}
              source={glb}
              progressAngle={i / shapes.length}
              progressRadius={i < 3 ? 0 : (i - 2) / shapes.length}
              scale={scale}
              open={isActive && id === openShapeId}
              active={isActive}
              paused={paused}
              firstVisit={isFirstVisit}
              pointerForceEnabled={!openShapeId}
              onPointerOver={onShapePointerOver}
              onPointerMove={onShapePointerMove}
              onPointerOut={onShapePointerOut}
              onPointerUp={handleProjectPointerUp}
            />
          ))}
        </group>
      </Physics>

      <Overlay
        active={isActive && !!openShapeId && isInOpenPosition}
        color={backgroundColor}
        position={[0, 0, 200]}
        onPointerOver={onOverlayPointerOver}
        onPointerMove={onOverlayPointerMove}
        onPointerOut={onOverlayPointerOut}
        onPointerDown={handleOverlayPointerDown}
        onPointerUp={handleOverlayPointerUp}
        renderOrder={1}
      />
    </>
  );
};

ShapesBox.propTypes = {
  shapes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      source: PropTypes.string,
      scale: PropTypes.number,
    })
  ),
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onShapePointerOver: PropTypes.func,
  onShapePointerOut: PropTypes.func,
  onOverlayPointerOver: PropTypes.func,
  onOverlayPointerMove: PropTypes.func,
  onOverlayPointerOut: PropTypes.func,
};
ShapesBox.defaultProps = {
  shapes: [],
  backgroundColor: "#000000",
  onShapePointerOver: f => f,
  onShapePointerOut: f => f,
  onOverlayPointerOver: f => f,
  onOverlayPointerMove: f => f,
  onOverlayPointerOut: f => f,
};

export default ShapesBox;
