import classnames from "classnames";
import PropTypes from "prop-types";
import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
  Suspense,
} from "react";
import { Canvas } from "react-three-fiber";
import * as THREE from "three";

import defaultLight from "@assets/3d-objects/light.glb";
import CameraOperator from "@components/CameraOperator";
import FadeLink from "@components/FadeLink";
import OrbitControls from "@components/OrbitControls";
import Stats from "@components/Stats";
import { isStorybook } from "@utils/isEnvironment";
import useComponentWillMount from "@utils/useComponentWillMount";
import { clearClones } from "@utils/useGLTFLoader";

import { DatGui, useConfig } from "./configs";
import ShapesBox from "./ShapesBox";
import { ShapesHeroLoaderContext } from "./Loader";
import "./style.css";

const CAMERA_SETTINGS = {
  near: 0.1,
  far: 10000,
  fov: 70,
  position: [0, 0, 400],
};
const BACKGROUND_COLOR = new THREE.Color("#FF8F84");

let isShapesHeroFirstVisit = true;

const updateClasses = (node, openShapeId, pointerOverState, openShapeUrl) => {
  const classes = [];
  const allClasses = [
    "cursor-type",
    "cursor-type--checkit",
    "cursor-type--close",
    "cursor-type--see-the-project",
    "cursor-type--none",
  ];

  if (!openShapeId && pointerOverState === "project") {
    classes.push("cursor-type");
    classes.push("cursor-type--checkit");
  } else if (openShapeId && pointerOverState === "overlay") {
    classes.push("cursor-type");
    classes.push("cursor-type--close");
  } else if (openShapeId && pointerOverState !== "overlay") {
    classes.push("cursor-type");
    if (!!openShapeUrl) {
      classes.push("cursor-type--see-the-project");
    } else {
      classes.push("cursor-type--none");
    }
  }

  allClasses
    .filter(ac => !classes.includes(c => c === ac))
    .forEach(c => node.classList.remove(c));
  classes.forEach(c => node.classList.add(c));
};

const ShapesHero = ({
  shapes,
  active: isActive,
  backgroundColor,
  className,
}) => {
  const ref = useRef();
  const pointerOverState = useRef(null);

  const [devicePixelRatio, setDevicePixelRatio] = useState(1);
  const [isStorybookMode, setIsStorybookMode] = useState(false);
  const [paused, setPaused] = useState(true);
  const [openShapeId, setOpenShapeId] = useState(null);
  const [lastActiveShape, setLastActiveShape] = useState(null);

  const { isLoaded: isLoadingComplete } = useContext(ShapesHeroLoaderContext);

  useComponentWillMount(clearClones);

  useEffect(() => {
    if (isStorybook()) {
      setIsStorybookMode(true);
    }

    setDevicePixelRatio(window.devicePixelRatio);
  }, [setIsStorybookMode]);

  useEffect(() => {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(event => {
        if (paused && event.isIntersecting) {
          setPaused(false);
        } else if (!paused && !event.isIntersecting) {
          setPaused(true);
        }
      });
    });

    obs.observe(ref.current);
    return () => obs.disconnect();
  }, [paused]);

  const { isEnabled: isFogEnabled, ...fogConfigs } = useConfig(
    config => config.fog
  );
  const { isOrbitControlsEnabled, ...cameraConfigs } = useConfig(
    config => config.camera
  );

  const handleCanvasCreate = useCallback(
    ({ gl }) => {
      gl.setClearColor(backgroundColor ?? BACKGROUND_COLOR);
      gl.toneMapping = THREE.PCFShadowMap;
    },
    [backgroundColor]
  );

  const openShapeUrl = useMemo(() => {
    if (!openShapeId) {
      return null;
    }

    const activeShape = shapes.find(({ id }) => id === openShapeId);
    if (!activeShape) {
      return null;
    }

    return activeShape?.url ?? null;
  }, [openShapeId, shapes]);

  const handleShapePointerOver = useCallback(() => {
    if (!openShapeId && pointerOverState.current !== "project") {
      pointerOverState.current = "project";
      updateClasses(
        ref.current,
        openShapeId,
        pointerOverState.current,
        openShapeUrl
      );
    }
  }, [openShapeId, openShapeUrl]);
  const handleShapePointerOut = useCallback(() => {
    if (!openShapeId && pointerOverState.current === "project") {
      pointerOverState.current = null;
      updateClasses(
        ref.current,
        openShapeId,
        pointerOverState.current,
        openShapeUrl
      );
    }
  }, [openShapeId, openShapeUrl]);
  const handleOverlayPointerOver = useCallback(() => {
    if (openShapeId && pointerOverState.current !== "overlay") {
      pointerOverState.current = "overlay";
      updateClasses(
        ref.current,
        openShapeId,
        pointerOverState.current,
        openShapeUrl
      );
    }
  }, [openShapeId, openShapeUrl]);
  const handleOverlayPointerOut = useCallback(() => {
    if (openShapeId && pointerOverState.current === "overlay") {
      pointerOverState.current = null;
      updateClasses(
        ref.current,
        openShapeId,
        pointerOverState.current,
        openShapeUrl
      );
    }
  }, [openShapeId, openShapeUrl]);

  const handleStatsReady = useCallback(statsElement => {
    if (ref.current) {
      ref.current.appendChild(statsElement);
    }
  }, []);

  useEffect(() => {
    if (openShapeId) {
      const activeShape = shapes.find(({ id }) => id === openShapeId);
      setLastActiveShape(activeShape);
    }
  }, [openShapeId, shapes]);

  useEffect(
    () => () => {
      // NOTE: we set this global variable to false on component unmount
      isShapesHeroFirstVisit = false;
    },
    []
  );

  return (
    <section
      ref={ref}
      className={classnames(
        "shapes-hero",
        {
          "shapes-hero--first-visit": isShapesHeroFirstVisit,
          "shapes-hero--active": isActive && isLoadingComplete,
          "shapes-hero--project-selected-once": !!lastActiveShape,
          "cursor-type cursor-type--checkit":
            !openShapeId && pointerOverState.current === "project",
          "cursor-type cursor-type--close":
            openShapeId && pointerOverState.current === "overlay",
          "cursor-type cursor-type--see-the-project":
            openShapeId && pointerOverState.current !== "overlay",
        },
        className
      )}
      style={{
        backgroundColor: backgroundColor.getStyle(),
      }}
    >
      <Canvas
        camera={CAMERA_SETTINGS}
        onCreated={handleCanvasCreate}
        pixelRatio={devicePixelRatio}
        concurrent={true}
        updateDefaultCamera
      >
        {isFogEnabled && (
          <fog
            attach="fog"
            args={[backgroundColor, fogConfigs.near, fogConfigs.far]}
          />
        )}

        <ambientLight />
        <pointLight position={[0, 1000, -10]} />
        <pointLight position={[0, 1000, 1000]} />
        <pointLight position={[0, -500, -10]} />
        <pointLight position={[0, 0, 500]} intensity={0.75} />

        <Suspense fallback={null}>
          <ShapesBox
            shapes={shapes}
            openShapeId={openShapeId}
            backgroundColor={backgroundColor}
            paused={paused || !isLoadingComplete}
            active={isActive}
            firstVisit={isShapesHeroFirstVisit}
            onShapePointerOver={handleShapePointerOver}
            onShapePointerMove={handleShapePointerOver}
            onShapePointerOut={handleShapePointerOut}
            onOverlayPointerOver={handleOverlayPointerOver}
            onOverlayPointerMove={handleOverlayPointerOver}
            onOverlayPointerOut={handleOverlayPointerOut}
            onOpenShapeIdChange={setOpenShapeId}
          />
        </Suspense>

        {!isOrbitControlsEnabled && <CameraOperator {...cameraConfigs} />}
        {isOrbitControlsEnabled && <OrbitControls />}

        {isStorybookMode && <Stats onReady={handleStatsReady} />}
      </Canvas>

      {isStorybookMode && <DatGui />}

      <h1 className="shapes-hero__title">
        We are&nbsp;
        <br />
        Roll Studio
      </h1>
      {lastActiveShape && lastActiveShape?.url && (
        <FadeLink
          to={lastActiveShape?.url ?? "#"}
          className={classnames(
            "shapes-hero__project-details cursor-type cursor-type--see-the-project",
            {
              "shapes-hero__project-details--hidden":
                !openShapeId || !lastActiveShape,
            }
          )}
        >
          {lastActiveShape?.title && (
            <h2 className="shapes-hero__project-details-title">
              {lastActiveShape.title}
            </h2>
          )}
          {lastActiveShape?.description && (
            <p className="shapes-hero__project-details-description">
              {lastActiveShape.description}
            </p>
          )}
        </FadeLink>
      )}
      {lastActiveShape && !lastActiveShape?.url && (
        <div
          className={classnames(
            "shapes-hero__project-details cursor-type cursor-type--none",
            {
              "shapes-hero__project-details--hidden":
                !openShapeId || !lastActiveShape,
            }
          )}
        >
          {lastActiveShape?.title && (
            <h2 className="shapes-hero__project-details-title">
              {lastActiveShape.title}
            </h2>
          )}
          {lastActiveShape?.description && (
            <p className="shapes-hero__project-details-description">
              {lastActiveShape.description}
            </p>
          )}
        </div>
      )}
    </section>
  );
};

ShapesHero.propTypes = {
  shapes: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      scale: PropTypes.number,
    })
  ),
  light: PropTypes.string,
};
ShapesHero.defaultProps = {
  shapes: [],
  light: defaultLight,
  backgroundColor: BACKGROUND_COLOR,
};

export default ShapesHero;
