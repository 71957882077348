import React, { Component, createRef } from "react";

import Container from "@components/Container";
import FadeInOnView from "@components/FadeInOnView";

import "./style.css";

class ProjectCredits extends Component {
  constructor(props) {
    super(props);

    this.projectCreditsRef = createRef();

    const creditsText = props.credits
      .map(
        credit =>
          `${credit.category}: ` +
          credit.names.map(item => `${item.name}`).join(", ")
      )
      .join(" | ");

    this.state = {
      credits: creditsText,
      originalCredits: creditsText,
    };
  }

  static defaultProps = {};

  setupCredits = () => {
    let creditsText = "";

    const span = this.projectCreditsRef.current.querySelector("span");

    do {
      creditsText += this.state.originalCredits + ` | `;
      span.textContent = creditsText;
    } while (this.projectCreditsRef.current.offsetWidth >= span.offsetWidth);

    this.setState({
      credits: creditsText,
    });
  };

  onWindowResize = () => {
    this.resizeTimeout && clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(this.setupCredits, 100);
  };

  componentDidMount() {
    this.setupCredits();
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  render() {
    const { credits } = this.state;

    return (
      <FadeInOnView className="project-credits" translate="none">
        <Container variant="small">
          <h4 className="project-credits__heading">Credits</h4>
        </Container>

        <div className="project-credits__marquee" ref={this.projectCreditsRef}>
          <div>
            <span>{credits}</span>
            <span>{credits}</span>
          </div>
        </div>
      </FadeInOnView>
    );
  }
}

export default ProjectCredits;
