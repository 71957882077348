import classnames from "classnames";
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import "./style.css";

const Heading = ({ originalContent, ...props }) => {
  const headingType = useMemo(() => {
    const heading = originalContent.match(/<(h[1-6])>/);
    return heading ? heading[1] : "h1";
  }, [originalContent]);

  return (
    <div
      className={classnames("heading-block", `heading-block--${headingType}`)}
      dangerouslySetInnerHTML={{ __html: originalContent }}
    />
  );
};

Heading.propTypes = {
  originalContent: PropTypes.string.isRequired,
};

export default Heading;
