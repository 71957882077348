import React from "react";
import PropTypes from "prop-types";
import ArrowLineAnimatedClients from "@components/ArrowLineAnimatedClients";
import ArrowLineAnimatedWeHelp from "@components/ArrowLineAnimatedWeHelp";

import "./style.css";

const DEFAULT_HEADER_COLOR = "auto";

const ArrowsAnimated = ({ acf }) => {
  const headerColor = acf?.arrows_headerColor ?? DEFAULT_HEADER_COLOR;
  const typology = acf?.arrow_typology ?? "";

  return typology == "ARROW_TYPOLOGY_1" ? (
    <ArrowLineAnimatedWeHelp className={`header-color__${headerColor}`} />
  ) : (
    <ArrowLineAnimatedClients className={`header-color__${headerColor}`} />
  );
};

ArrowsAnimated.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default ArrowsAnimated;
