import { useRef, useState, useCallback } from "react";

const usePreviousState = value => {
  const valueRef = useRef(value);
  const [state, setState] = useState({
    previous: undefined,
    current: value,
  });

  const handleSetValue = useCallback(newValue => {
    setState({
      previous: valueRef.current,
      current: newValue,
    });

    valueRef.current = newValue;
  }, []);

  return [state, handleSetValue];
};

export default usePreviousState;
