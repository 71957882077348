import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import useInView from "use-in-view";

import Container from "@components/Container";
import Image from "@components/Image";
import Video from "@components/Video";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";

const DEFAULT_TEXT_COLOR = "black";
const DEFAULT_BACKGROUND_COLOR = "white";
const DEFAULT_MARGIN_SETTING = "normal";

const DEFAULT_HEADER_COLOR = "auto";

const FourImagesModule = ({ acf }) => {
  const topLeftUrl = acf?.topLeft?.media?.mediaItemUrl ?? "";
  const topLeftType = acf?.topLeft?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;
  const topLeftCaption = acf?.topLeft?.caption ?? "";

  const topRightUrl = acf?.topRight?.media?.mediaItemUrl ?? "";
  const topRightType = acf?.topRight?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;
  const topRightCaption = acf?.topRight?.caption ?? "";

  const bottomLeftUrl = acf?.bottomLeft?.media?.mediaItemUrl ?? "";
  const bottomLeftType =
    acf?.bottomLeft?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;
  const bottomLeftCaption = acf?.bottomLeft?.caption ?? "";

  const bottomRightUrl = acf?.bottomRight?.media?.mediaItemUrl ?? "";
  const bottomRightType =
    acf?.bottomRight?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;
  const bottomRightCaption = acf?.bottomRight?.caption ?? "";

  const textColor = acf?.fourImagesModule_textColor ?? DEFAULT_TEXT_COLOR;
  const backgroundColor =
    acf?.fourImagesModule_backgroundColor ?? DEFAULT_BACKGROUND_COLOR;

  const TopLeftMedia = topLeftType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const TopRightMedia = topRightType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const BottomLeftMedia = bottomLeftType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const BottomRightMedia =
    bottomRightType === DEFAULT_MEDIA_TYPE ? Image : Video;

  const marginTop = acf?.fourImagesModule_marginTop ?? DEFAULT_MARGIN_SETTING;
  const marginBottom =
    acf?.fourImagesModule_marginBottom ?? DEFAULT_MARGIN_SETTING;

  const headerColor = acf?.fourImagesModule_headerColor ?? DEFAULT_HEADER_COLOR;

  const [ref, isInView] = useInView();

  return (
    <div
      ref={ref}
      className={classnames(
        "four-images-module",
        `margin-top--${marginTop}`,
        `margin-bottom--${marginBottom}`,
        `header-color--${headerColor}`,
        {
          "four-images-module--active": isInView,
        }
      )}
      style={{ backgroundColor: backgroundColor }}
    >
      <Container
        variant="small"
        style={{
          backgroundColor: backgroundColor,
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "3.5rem",
        }}
      >
        <div className="left-col">
          <div className="top-left">
            <TopLeftMedia
              src={topLeftUrl}
              objectPosition="center"
              autoPlay
              loop
            />
            <p
              className="caption-desktop"
              style={{ color: textColor === "black" ? "#7B7B7B" : "white" }}
            >
              {topLeftCaption}
            </p>
          </div>

          <div className="bottom-left">
            <BottomLeftMedia
              src={bottomLeftUrl}
              objectPosition="center"
              autoPlay
              loop
            />
            <p
              className="caption-desktop"
              style={{ color: textColor === "black" ? "#7B7B7B" : "white" }}
            >
              {bottomLeftCaption}
            </p>
          </div>
        </div>
        <div className="right-col">
          <div className="top-right">
            <TopRightMedia
              src={topRightUrl}
              objectPosition="center"
              autoPlay
              loop
            />
            <p
              className="caption-desktop"
              style={{ color: textColor === "black" ? "#7B7B7B" : "white" }}
            >
              {topRightCaption}
            </p>
          </div>

          <div className="bottom-right">
            <BottomRightMedia
              src={bottomRightUrl}
              objectPosition="center"
              autoPlay
              loop
            />
            <p
              className="caption-desktop"
              style={{ color: textColor === "black" ? "#7B7B7B" : "white" }}
            >
              {bottomRightCaption}
            </p>
          </div>
        </div>
      </Container>
      <p
        className="caption-mobile"
        style={{ color: textColor === "black" ? "#7B7B7B" : "white" }}
      >
        {topLeftCaption}, {topRightCaption}, {bottomLeftCaption},{" "}
        {bottomRightCaption}
      </p>
    </div>
  );
};

FourImagesModule.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default FourImagesModule;
