import PropTypes from "prop-types";
import React from "react";

import availableBlocks from "@blocks/index";
import UnknownBlock from "@blocks/UnknownBlock";

const BlocksRenderer = ({
  blocks,
  includeGrids = true,
  entered = true,
  entering = true,
  template = "",
  url = "",
}) => (
  <>
    {blocks.map((data, i) => {
      let Block = UnknownBlock;

      if (availableBlocks.hasOwnProperty(data.__typename)) {
        Block = availableBlocks[data.__typename].default;
      }

      return (
        <Block
          key={`block-${i}--${data.name}`}
          {...data}
          includeGrids={includeGrids}
          entered={entered}
          entering={entering}
          template={template}
          url={url}
        />
      );
    })}
  </>
);

BlocksRenderer.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};
BlocksRenderer.defaultProps = {
  blocks: [],
};

export default BlocksRenderer;
