import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import AnimatedTextOnView from "@components/AnimatedTextOnView";
import Grid from "@components/Grid";
import SimpleContainer from "@components/Container";
import FadeInOnView from "@components/FadeInOnView";

import "./style.css";

const DEFAULT_TEXT_COLOR = "black";
const DEFAULT_BACKGROUND_COLOR = "white";

const DEFAULT_MARGIN_SETTING = "normal";

const DEFAULT_HEADER_COLOR = "auto";

const TitleText = ({ acf }) => {
  const title = acf?.title ?? "";
  const text = acf?.text ?? "";

  const {
    titleText_wrapInGrid: wrapInGrid,
    titleText_hasNarrowMargins: hasNarrowMargins,
    titleText_isFluid: isFluid,
    titleText_mobileOffset: mobileOffset,
    titleText_mobileColumns: mobileColumns,
    titleText_desktopOffset: desktopOffset,
    titleText_desktopColumns: desktopColumns,
  } = acf;

  const containerStyle = {
    color: acf?.titleText_textColor ?? DEFAULT_TEXT_COLOR,
    backgroundColor: acf?.titleText_backgroundColor ?? DEFAULT_BACKGROUND_COLOR,
  };

  const classes = classnames(
    "title-text",
    `margin-top--${acf?.titleText_marginTop ?? DEFAULT_MARGIN_SETTING}`,
    `margin-bottom--${acf?.titleText_marginBottom ?? DEFAULT_MARGIN_SETTING}`,
    `header-color--${acf.titleText_headerColor ?? DEFAULT_HEADER_COLOR}`,
    acf.textCenter ? "text-center" : ""
  );

  const Container = useCallback(
    ({ children }) => {
      if (wrapInGrid) {
        return (
          <Grid.Container fluid={isFluid} narrowMargins={hasNarrowMargins}>
            <Grid.Row>
              <Grid.Cell
                offset={desktopOffset}
                columns={desktopColumns}
                offsetMobile={mobileOffset}
                columnsMobile={mobileColumns}
              >
                {children}
              </Grid.Cell>
            </Grid.Row>
          </Grid.Container>
        );
      }

      return <SimpleContainer variant="small">{children}</SimpleContainer>;
    },
    [wrapInGrid, mobileOffset, mobileColumns, desktopOffset, desktopColumns]
  );

  return (
    <div className={classes} style={containerStyle}>
      <Container>
        <h2>
          <AnimatedTextOnView text={title} />
        </h2>
        <FadeInOnView>
          <div
            className="title-text--copy"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </FadeInOnView>
      </Container>
    </div>
  );
};

TitleText.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default TitleText;
