import { useRef, useEffect, useState, useCallback } from "react";

const usePreloadedImage = src => {
  const img = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const handleLoad = useCallback(() => setIsLoaded(true), [setIsLoaded]);
  const handleError = useCallback(e => setError(e), [setError]);

  useEffect(() => {
    if (!img.current) {
      img.current = new Image();
    }

    img.current.onload = handleLoad;
    img.current.onerror = handleError;
    img.current.src = src;

    setIsLoaded(img.current.complete);
  }, [img, handleLoad, handleError, src, setIsLoaded]);

  return { isLoaded, error, src };
};

export default usePreloadedImage;
