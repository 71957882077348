import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useBox } from "use-cannon";

const BoxCollider = ({
  children,
  size,
  mass,
  position,
  angularFactor,
  linearFactor,
  linearDamping,
  angularDamping,
  onReady = f => f,
  wireframe,
  color,
  onPointerDown,
  onPointerUp,
  onPointerMove,
  onCollide = f => f,
  ...props
}) => {
  // Setup collision box
  const [ref, api] = useBox(() => ({
    args: [size[0] / 2, size[1] / 2, size[2] / 2],
    mass,
    position,
    angularFactor,
    linearFactor,
    linearDamping,
    angularDamping,
    onCollide,
  }));

  useEffect(() => {
    if (api) {
      onReady({ ref, api });
    }
  }, [ref, api, onReady]);

  // Update body params on prop change
  useEffect(() => {
    api.angularFactor.set(...angularFactor);
    if (angularFactor[0] + angularFactor[1] + angularFactor[2] === 0) {
      api.angularVelocity.set(0, 0, 0);
    }
  }, [api.angularFactor, api.angularVelocity, angularFactor]);

  useEffect(() => {
    api.linearFactor.set(...linearFactor);
    if (linearFactor[0] + linearFactor[1] + linearFactor[2] === 0) {
      api.velocity.set(0, 0, 0);
    }
  }, [api.linearFactor, linearFactor, api.velocity]);

  return (
    <group ref={ref} {...props}>
      {children}

      <mesh
        visible={wireframe}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerMove={onPointerMove}
      >
        <boxBufferGeometry attach="geometry" args={size} />
        <meshBasicMaterial attach="material" color={color} wireframe />
      </mesh>
    </group>
  );
};

BoxCollider.propTypes = {
  size: PropTypes.arrayOf(PropTypes.number).isRequired,
  mass: PropTypes.number,
  onReady: PropTypes.func,
  children: PropTypes.node.isRequired,
  wireframe: PropTypes.bool,
  color: PropTypes.string,
};

BoxCollider.defaultProps = {
  mass: 1,
  onReady: f => f,
  wireframe: false,
  color: "red",
};

export default BoxCollider;
