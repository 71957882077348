import React, {
  createContext,
  useRef,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { CSSTransition } from "react-transition-group";
import { DefaultLoadingManager } from "three";

import { ReactComponent as RollCircle } from "@assets/images/roll-circle.svg";

export const ShapesHeroLoaderContext = createContext();

const Loader = ({ onStart, onLoad, homepage: isHomepage }) => {
  const progress = useRef(0);
  const [errors, setErrors] = useState([]);

  const { isLoading, isLoaded } = useContext(ShapesHeroLoaderContext);

  const handleStart = useCallback(() => {
    progress.current = 0;
    onStart();
  }, [onStart]);
  const handleError = useCallback(item => setErrors([...errors, item]), [
    errors,
  ]);
  const handleProgress = useCallback((_, loaded, total) => {
    progress.current = loaded / total;
  }, []);

  useEffect(() => {
    DefaultLoadingManager.onStart = handleStart;
    DefaultLoadingManager.onLoad = onLoad;
    DefaultLoadingManager.onError = handleError;
    DefaultLoadingManager.onProgress = handleProgress;
  }, [handleStart, onLoad, handleError, handleProgress]);
  useEffect(() => {
    if (isLoading || (isHomepage && !isLoaded)) {
      document.body.classList.add("shapes-hero-loader-scroll-lock");
    } else {
      document.body.classList.remove("shapes-hero-loader-scroll-lock");
    }
  }, [isLoading, isHomepage, isLoaded]);

  return (
    <CSSTransition
      in={isLoading && !isLoaded}
      timeout={{ enter: 1600, exit: 2000 }}
      classNames="shapes-hero-loader-"
      mountOnEnter
      unmountOnExit
    >
      <div className="shapes-hero-loader">
        <RollCircle className="shapes-hero-loader__roll-circle" />
      </div>
    </CSSTransition>
  );
};

export default Loader;
