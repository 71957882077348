import classnames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Grid from "@components/Grid";
import TeamMembersFaces from "@components/TeamMembersFaces";

import "./style.css";

const DEFAULT_HEADER_COLOR = "auto";

const Team = ({ acf }) => {
  const title = acf?.title ?? "";
  const description = acf?.description ?? "";

  const textColor = acf?.textColor ?? "#000000";
  const backgroundColor = acf?.backgroundColor ?? "#ffffff";

  const headerColor = acf?.stats_headerColor ?? DEFAULT_HEADER_COLOR;

  return (
    <div
      className={classnames("team", `header-color--${headerColor}`)}
      style={{
        color: textColor,
        backgroundColor: backgroundColor,
      }}
    >
      <Grid.Container narrowMargins>
        <Grid.Row>
          <Grid.Cell
            mobileColumns={4}
            columns={8}
            offset={2}
            className="team__intro"
          >
            <h2 className="team__title">{title}</h2>
            <div
              className="team__description"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>

      <TeamMembersFaces />

      <div className="team__join-cta-wrapper">
        <Link className="team__join-cta" to="/careers">
          Join the team
        </Link>
      </div>
    </div>
  );
};

Team.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default Team;
