import classnames from "classnames";
import React, { useRef, useState, useCallback, useEffect } from "react";
import useInView from "use-in-view";

import SocialsList from "./Socials";
import useTeamMembers from "./useTeamMembers";
import "./style.css";

const SCROLL_MODE_DEFAULT_SPEED = -0.1;
const SCROLL_MODE_INERTIA_THRESHOLD = 0.3;

const Y_SEQUENCE = Array.from({ length: 10 }, () => Math.random());

const TeamMembersFaces = () => {
  const [inViewRef, isInView] = useInView();
  const wrapperRef = useRef();

  // get team members from WPGraphQL
  const members = useTeamMembers();

  // keep in state the active face indexes list
  const [membersActiveFaces, setMembersActiveFaces] = useState(
    members.map(() => 0)
  );

  const animation = useRef({
    raf: null,
    members,
    isInView,
    offsetX: 0,
    isMemberActive: false,
    isUserScrolling: false,
    scrollStartX: 0,
    scrollDeltaX: 0,
    scrollStartTime: 0,
    scrollSpeed: SCROLL_MODE_DEFAULT_SPEED,
  });

  useEffect(() => {
    animation.current.isInView = isInView;
  }, [isInView]);

  const animate = useCallback(() => {
    if (!animation.current.raf) {
      return;
    }

    // filter only mounted members
    const mountedMembers = animation.current.members.filter(
      member => !!member.ref
    );

    const membersSumWidth = mountedMembers.reduce(
      (sum, { ref }) => sum + ref.offsetWidth,
      0
    );

    // NOTE: check if there is enough room for members. If not, enable the
    //       scroll mode.
    //       Here we allow 5% of overlap on each side of the members, so we
    //       consider only the 90% of the members width. Other then this we
    //       state that there is enough room when also the info box of the
    //       rightmost member is completely visible (200px wide). Since the
    //       members are center aligned, we sum 200 twice (one for each side).
    const isScrollMode =
      membersSumWidth * 0.9 + 400 > wrapperRef.current.offsetWidth;

    // NOTE: if we are in scroll mode faces will spread on a wider area, so that
    //       we have a maximum of %5 overlap per side
    const stageWidth = isScrollMode
      ? Math.max(wrapperRef.current.offsetWidth, membersSumWidth * 0.9)
      : wrapperRef.current.offsetWidth;
    const stageHeight = wrapperRef.current.offsetHeight;

    // initialize faces positions changed flag
    let areFacesChanged = false;

    mountedMembers.forEach(member => {
      const index = parseInt(member.ref.dataset.index, 10);
      const memberWidth = member.ref.offsetWidth;
      const memberHeight = member.ref.offsetHeight;

      // set the x position to 0.5 by default (in case we have 1 member only)
      let normX = 0.5;

      if (mountedMembers.length > 1) {
        if (isScrollMode) {
          // if is scroll mode, place it along the whole width
          normX = index / mountedMembers.length;
        } else {
          // if is not scroll mode, place it along the whole width leaving 1
          // slot empty on each side of the screen
          normX = (index + 1) / (mountedMembers.length + 1);
        }
      }

      // alternate y position for each member picking from y static sequence
      const normY = Y_SEQUENCE[index % Y_SEQUENCE.length];

      // calculate the x and y position in pixels
      let x = isScrollMode
        ? // if is scroll mode, full excursion is `stageWidth - memberWidth` to
          // guarantee even gaps. Then add memberWidth so that first element is
          // completely visible and not on the edge
          normX * (stageWidth + memberWidth) + memberWidth
        : // if is not scroll mode, full excursion is `stageWidth - memberWidth`
          // since there are empty slots on each side of the screen, then add
          // half of the member width so that the members are centered
          normX * (stageWidth - memberWidth) + memberWidth / 2;
      const y = normY * (stageHeight - memberHeight) + memberHeight / 2;

      if (isScrollMode) {
        // update speed and offset only when is automatically scrolling and when
        // no member is active
        if (
          !animation.current.isUserScrolling &&
          !animation.current.isMemberActive
        ) {
          // continuously change the speed with damping to reach default value
          animation.current.scrollSpeed +=
            (SCROLL_MODE_DEFAULT_SPEED - animation.current.scrollSpeed) * 0.01;

          // apply speed to offset
          animation.current.offsetX += animation.current.scrollSpeed;
        } else if (animation.current.isMemberActive) {
          animation.current.scrollSpeed = SCROLL_MODE_DEFAULT_SPEED;
        }

        // apply offset to the x position
        x += animation.current.offsetX;

        // if the user is scrolling add the user scroll delta to the x position
        if (animation.current.isUserScrolling) {
          x += animation.current.scrollDeltaX;
        }

        // if the member is out of the screen, move it to the other side, and
        // calculate the face index that iterates on every jump
        let faceIndex = 0;
        if (x < -memberWidth / 2) {
          while (x < -memberWidth / 2) {
            x += stageWidth + memberWidth;
            faceIndex++;
          }
        } else if (x > stageWidth + memberWidth / 2) {
          while (x > stageWidth + memberWidth / 2) {
            x -= stageWidth + memberWidth;
            faceIndex++;
          }
        }

        faceIndex = faceIndex % member.node.acf.faces.length;

        // if face index changes update stored value and flag faces changed
        if (faceIndex !== member.faceIndex) {
          areFacesChanged = true;
          member.faceIndex = faceIndex;
        }
      } else {
        // if is not scroll mode, reset the face index to 0
        // reset the offset value
        animation.current.offsetX = 0;
      }

      member.ref.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`;
    });

    // if at least one face changed, update state (and trigger re-render)
    if (areFacesChanged) {
      setMembersActiveFaces(
        mountedMembers.map(member => member.faceIndex || 0)
      );
    }

    if (animation.current.isInView) {
      animation.current.raf = requestAnimationFrame(animate);
    }
  }, []);

  useEffect(() => {
    if (isInView) {
      animation.current.raf = requestAnimationFrame(animate);
    }

    return () => {
      animation.current.raf = cancelAnimationFrame(animation.current.raf);
    };
  }, [isInView, animate]);

  // handlers for member activation (highlight)
  const [activeItemIndex, setActiveItemIndex] = useState(-1);
  const handleMemberActivation = useCallback(e => {
    e.stopPropagation();

    animation.current.isMemberActive = true;

    const index = parseInt(e.currentTarget.dataset.index, 10);
    setActiveItemIndex(index);
  }, []);
  const handleMemberDeactivation = useCallback(() => {
    animation.current.isMemberActive = false;
    setActiveItemIndex(-1);
  }, []);
  useEffect(() => {
    window.addEventListener("click", handleMemberDeactivation);

    return () => {
      window.removeEventListener("click", handleMemberDeactivation);
    };
  }, [handleMemberDeactivation]);

  // handlers for scroll mode
  const handlePointerMove = useCallback(e => {
    // get pointer x based on the event type
    const pointerX = e.type === "touchmove" ? e.touches[0].clientX : e.clientX;

    // calculate the difference between the initial x position and the current one
    animation.current.scrollDeltaX = pointerX - animation.current.scrollStartX;
  }, []);
  const handlePointerUp = useCallback(
    e => {
      if (animation.current.scrollDeltaX !== 0) {
        // apply the delta to the offset
        animation.current.offsetX += animation.current.scrollDeltaX;

        // apply inertia if the speed is above a certain threshold
        const scrollSpeed =
          animation.current.scrollDeltaX /
          (Date.now() - animation.current.scrollStartTime);

        if (Math.abs(scrollSpeed) > SCROLL_MODE_INERTIA_THRESHOLD) {
          animation.current.scrollSpeed = scrollSpeed;
        }
      }

      animation.current.isUserScrolling = false;

      // unregister handlers
      if (e.type !== "mouseup") {
        window.removeEventListener("touchmove", handlePointerMove);
        window.removeEventListener("touchend", handlePointerUp);
        window.removeEventListener("touchcancel", handlePointerUp);
      } else {
        window.removeEventListener("mousemove", handlePointerMove);
        window.removeEventListener("mouseup", handlePointerUp);
      }
    },
    [handlePointerMove]
  );
  const handlePointerDown = useCallback(
    e => {
      // get pointer x based on the event type
      const pointerX =
        e.type === "touchstart" ? e.touches[0].clientX : e.clientX;

      // save initial x position
      animation.current.scrollStartX = pointerX;
      animation.current.scrollDeltaX = 0;
      animation.current.scrollStartTime = Date.now();
      animation.current.isUserScrolling = true;

      // register handlers
      if (e.type === "touchstart") {
        window.addEventListener("touchmove", handlePointerMove);
        window.addEventListener("touchend", handlePointerUp);
        window.addEventListener("touchcancel", handlePointerUp);
      } else {
        window.addEventListener("mousemove", handlePointerMove);
        window.addEventListener("mouseup", handlePointerUp);
      }
    },
    [handlePointerMove, handlePointerUp]
  );

  useEffect(() => {
    return () => {
      // ensure that the event handlers are removed when the component is
      // unmounted
      window.removeEventListener("mousemove", handlePointerMove);
      window.removeEventListener("mouseup", handlePointerUp);
      window.removeEventListener("touchmove", handlePointerMove);
      window.removeEventListener("touchend", handlePointerUp);
      window.removeEventListener("touchcancel", handlePointerUp);
    };
  }, [handlePointerUp, handlePointerMove]);

  return (
    <div
      className={classnames("team-members-faces", {
        "team-members-faces--active": activeItemIndex >= 0,
        "in-view": isInView,
      })}
      ref={ref => {
        wrapperRef.current = ref;
        inViewRef(ref);
      }}
      onMouseDown={handlePointerDown}
      onTouchStart={handlePointerDown}
    >
      {members.map((member, memberIndex) => (
        <div
          key={memberIndex}
          ref={ref =>
            (animation.current.members[memberIndex] = animation.current.members[
              memberIndex
            ]
              ? {
                  ...animation.current.members[memberIndex],
                  ref,
                }
              : {
                  ref,
                  index: memberIndex,
                })
          }
          className={classnames("team-members-faces__item", {
            "team-members-faces__item--active": memberIndex === activeItemIndex,
          })}
          data-index={memberIndex}
          onClick={handleMemberActivation}
          onMouseEnter={handleMemberActivation}
          onMouseLeave={handleMemberDeactivation}
        >
          <div
            className="team-members-faces__item-faces"
            style={{
              animationDelay: `${Y_SEQUENCE[memberIndex % Y_SEQUENCE.length] *
                5}s`,
            }}
          >
            <div
              className="team-members-faces__item-faces-inner"
              style={{
                animationDelay: `${Y_SEQUENCE[
                  (memberIndex + 1) % Y_SEQUENCE.length
                ] * 10}s`,
              }}
            >
              {member?.node?.acf?.faces?.map((face, faceIndex) => (
                <img
                  key={faceIndex}
                  src={face?.mediaItemUrl}
                  width={face?.mediaDetails?.width / 2}
                  height={face?.mediaDetails?.height / 2}
                  alt={member?.node?.title}
                  className={classnames("team-members-faces__item-face", {
                    "team-members-faces__item-face--active":
                      membersActiveFaces[memberIndex] === faceIndex,
                  })}
                />
              ))}
            </div>
          </div>

          <div className="team-members-faces__item-info">
            <h3 className="team-members-faces__item-name">
              {member?.node?.title}
            </h3>
            <div className="team-members-faces__item-role">
              {member?.node?.acf?.role}
            </div>

            <SocialsList
              className="team-members-faces__item-socials"
              socials={member?.node?.acf?.socials}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamMembersFaces;
