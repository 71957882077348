import classnames from "classnames";
import React from "react";
import Grid from "@components/Grid";
import DrawLine from "./DrawLine";
import "./style.css";

function ArrowLineAnimatedWeHelp({ className }) {
  return (
    <div className={classnames("arrow-line-animated-we-help", className)}>
      <Grid.Container narrowMargins>
        <Grid.Row>
          <Grid.Cell mobileColumns={4} columns={7} offset={1} className="cell">
            <DrawLine />
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
}

export default ArrowLineAnimatedWeHelp;
