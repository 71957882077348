import React from "react";
import PropTypes from "prop-types";
import * as THREE from "three";

import Intro from "@components/Intro";
import {
  Wrapper as SectionSlidesWrapper,
  Slide as SectionSlide,
} from "@components/SectionSlides";
import ShapesHeroComponent from "@components/ShapesHero";

const DEFAULT_HEADER_COLOR = "auto";

// WARNING: This is an incomplete block. Rename "".graphql.disabled" to "".graphql"
//          to enable it and be sure that at least one block is present in
//          wordpress.

const ShapesHero = ({ acf, entered, entering }) => {
  // const showTitle = !!acf?.show_title;
  // const title = acf?.title ?? null;
  const headerColor = acf?.headerColor ?? DEFAULT_HEADER_COLOR;
  const backgroundColor = new THREE.Color(acf?.background_color ?? "#FF8F84");
  const shapes = (acf?.shapes ?? [])
    .filter(s => !!s?.model?.mediaItemUrl ?? false)
    .map(s => ({
      id: s?.model?.id ?? "",
      glb: s?.model?.mediaItemUrl ?? null,
      scale: s?.model_scale ?? 1,
      title: s?.title ?? "",
      description: s?.description ?? "",
      url: s?.link ?? null,
      hoverLabel: s?.hover_label ?? null,
    }));

  return (
    <SectionSlidesWrapper className="header-color--light">
      <SectionSlide>
        {isActive => (
          <ShapesHeroComponent
            className={`header-color--${headerColor}`}
            shapes={shapes}
            backgroundColor={backgroundColor}
            active={isActive}
          />
        )}
      </SectionSlide>
      <SectionSlide>{isActive => <Intro active={isActive} />}</SectionSlide>
    </SectionSlidesWrapper>
  );
};

ShapesHero.propTypes = {
  acf: PropTypes.object.isRequired,
  entered: PropTypes.bool,
};
ShapesHero.defaultProps = {
  entered: false,
};

export default ShapesHero;
