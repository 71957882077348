import React, { useState, useEffect } from "react";
import classnames from "classnames";

import { isProduction } from "@utils/isEnvironment";

import "./style.css";

const UnknownBlock = data => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (!isProduction()) {
      setIsVisible(true);
    }
  }, [setIsVisible]);

  return (
    <section
      className={classnames("unknown-block", {
        "unknown-block--visible": isVisible,
      })}
      data-name={data.name}
    >
      {isVisible && (
        <>
          <h2>
            <span>{data.name}</span>
          </h2>
          <p>
            There is no definition for block <code>{data.__typename}</code>.
          </p>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </>
      )}
    </section>
  );
};

export default UnknownBlock;
