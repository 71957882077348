import PropTypes from "prop-types";
import React from "react";
import useInView from "use-in-view";

import AnimatedText from "@components/AnimatedText";
import Container from "@components/Container";
import MorphLink from "@components/MorphLink";

import "./style.css";

const DEFAULT_TEXT_COLOR = "white";

function getCoverData(blocks) {
  const types = ["WPGraphQL_AcfProjectCoverBlock", "AcfProjectCoverBlock"];
  for (let i = 0, l = blocks.length; i < l; i++) {
    if (types.indexOf(blocks[i].__typename) !== -1) {
      return blocks[i];
    }
  }
  return false;
}

const NextProjectCover = props => {
  const {
    project: { uri: link, blocks },
    exiting,
  } = props;

  const [ref, isInView] = useInView();

  const block = getCoverData(blocks);

  const coverImage = block.acf?.image?.sourceUrl;
  const coverImageMobile = block.acf?.alternativeImage?.sourceUrl ?? coverImage;

  const textColor = block.acf?.projectCover_textColor;

  return (
    block && (
      <div
        ref={ref}
        className="next-project-cover"
        style={{ color: textColor }}
      >
        <Container
          variant="full"
          style={{
            "--cover-image": `url(${coverImage})`,
            "--cover-image-mobile": `url(${coverImageMobile})`,
          }}
        >
          <MorphLink
            className="cursor-type--click"
            to={`/${link}`}
            duration={2}
            morph={{
              from: `.next-project-cover .container`,
              to: ".project-cover .container",
            }}
          >
            <div className="next-project-cover__heading">
              <h4 style={{ color: textColor }}>
                <AnimatedText
                  text={"Next project"}
                  fadeIn={isInView && !exiting}
                  fadeOut={exiting}
                  delay={240}
                  easing="var(--t--ease-out-quint)"
                  duration={2000}
                />
              </h4>
              <h1 style={{ color: textColor }}>
                <AnimatedText
                  text={block.acf.tagline}
                  fadeIn={isInView && !exiting}
                  fadeOut={exiting}
                  delay={240}
                  blockDelay={240}
                  easing="var(--t--ease-out-quint)"
                  duration={2000}
                />
              </h1>
            </div>
          </MorphLink>
        </Container>
      </div>
    )
  );
};

NextProjectCover.propTypes = {
  acf: PropTypes.object,
  tagline: PropTypes.string,
  imageUrl: PropTypes.string,
  textColor: PropTypes.string,
  link: PropTypes.string,
};
NextProjectCover.defaultProps = {
  textColor: DEFAULT_TEXT_COLOR,
};

export default NextProjectCover;
