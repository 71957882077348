import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import { tablet } from "@styles/breakpoints";

import "./style.css";

const ScrollIcon = ({ className }) => {
  const [hasMouse, setHasMouse] = useState(true);

  const handleTouchStart = useCallback(() => {
    if (hasMouse) {
      setHasMouse(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart);

    if (window.innerWidth < tablet) {
      setHasMouse(false);
    }

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, [handleTouchStart]);

  return (
    <div
      className={classnames(
        "scroll-icon",
        {
          "scroll-icon--mouse": hasMouse,
        },
        className
      )}
    >
      <div className="scroll-icon__wheel" />
    </div>
  );
};

export default ScrollIcon;
