import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import useInView from "use-in-view";

import Container from "@components/Container";
import Image from "@components/Image";
import Video from "@components/Video";
import FadeInOnView from "@components/FadeInOnView";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";

const DEFAULT_TEXT_COLOR = "black";
const DEFAULT_BACKGROUND_COLOR = "white";

const DEFAULT_MARGIN_SETTING = "normal";

const DEFAULT_HEADER_COLOR = "auto";

const MediaTextGallery = ({ acf }) => {
  const imageToExpand = useRef();
  const [imagesBlock, isImagesBlockInView] = useInView();

  const title = acf?.title ?? "";

  const topText = acf?.topText ?? "";
  const bottomText = acf?.bottomText ?? "";

  const textColor = acf?.mediaTextGallery_textColor ?? DEFAULT_TEXT_COLOR;
  const backgroundColor =
    acf?.mediaTextGallery_backgroundColor ?? DEFAULT_BACKGROUND_COLOR;

  const reversed = acf?.reversed ?? false;

  // const onAboutPage = acf?.onaboutpage ?? false;

  let resources = acf?.mediaResources ?? [];
  if (resources.length === 2) {
    resources = [undefined, ...resources];
  } else if (resources.length === 1) {
    resources = [undefined, resources[0], undefined];
  }

  const firstLeftMediaUrl = resources[0]?.media?.mediaItemUrl ?? "";
  const firstLeftMediaType =
    resources[0]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const secondLeftMediaUrl = resources[1]?.media?.mediaItemUrl ?? "";
  const secondLeftMediaType =
    resources[1]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const rightMediaUrl = resources[2]?.media?.mediaItemUrl ?? "";
  const rightMediaType = resources[2]?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const FirstLeftMedia =
    firstLeftMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const SecondLeftMedia =
    secondLeftMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const RightMedia = rightMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;

  const classes = classnames(
    "media-text-gallery",
    `margin-top--${acf?.mediaTextGallery_marginTop ?? DEFAULT_MARGIN_SETTING}`,
    `margin-bottom--${acf?.mediaTextGallery_marginBottom ??
      DEFAULT_MARGIN_SETTING}`,
    {
      "media-text-gallery--reversed": reversed,
      // disabling the cursor because we don't have a playground page yet
      // "cursor-type cursor-type--rotate-text": onAboutPage,
    },
    `header-color--${acf?.mediaTextGallery_headerColor ?? DEFAULT_HEADER_COLOR}`
  );

  // const onClick = () => {
  //   navigate("/playground/");
  // };

  return (
    <div
      className={classes}
      translate="none"
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <Container
        variant="large"
        style={{
          color: textColor,
        }}
      >
        <FadeInOnView className="media-text-gallery__title">
          <h2>{title}</h2>
          {topText && (
            <div
              className="media-text-gallery__text-content"
              dangerouslySetInnerHTML={{ __html: topText }}
            />
          )}
        </FadeInOnView>

        <div
          ref={imagesBlock}
          className={classnames("media-text-gallery__gallery", {
            "media-text-gallery__gallery--active": isImagesBlockInView,
          })}
        >
          <div
            className="media-text-gallery__gallery__left"
            ref={imageToExpand}
          >
            <h2>{title}</h2>
            {topText && (
              <div
                className="media-text-gallery__text-content"
                dangerouslySetInnerHTML={{ __html: topText }}
              />
            )}
            {firstLeftMediaUrl && (
              <FirstLeftMedia
                className="media-text-gallery__first-left-media"
                src={firstLeftMediaUrl}
                objectPosition="center"
              />
            )}
            <SecondLeftMedia
              className={"media-text-gallery__second-left-media"}
              src={secondLeftMediaUrl}
              objectPosition="center"
            />
          </div>
          <div className="media-text-gallery__gallery__right">
            {rightMediaUrl && (
              <RightMedia
                className="media-text-gallery__right-media"
                src={rightMediaUrl}
                objectPosition="center"
              />
            )}
            <p
              className="media-text-gallery__right-text"
              dangerouslySetInnerHTML={{ __html: bottomText }}
            />
          </div>
        </div>
        <p
          className="media-text-gallery__text"
          dangerouslySetInnerHTML={{ __html: bottomText }}
        />
      </Container>
    </div>
  );
};

MediaTextGallery.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default MediaTextGallery;
