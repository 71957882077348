import { useMemo } from "react";

import { tablet, laptop } from "@styles/breakpoints";
import useResize from "@utils/useResize";

export default () => {
  const { width } = useResize();
  return useMemo(
    () => ({
      isMobile: width < tablet,
      isTablet: width >= tablet && width < laptop,
      isLaptop: width >= laptop,
    }),
    [width]
  );
};
