import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

// Instantiate the loader
const loader = new GLTFLoader();

const cache = {};

const cloneData = data => {
  const clone = {
    nodes: {},
    materials: {},
    scene: data.scene.clone(),
  };

  // console.log("useGLTFLoader.cloneData");

  if (clone.scene) {
    clone.nodes = {};
    clone.materials = {};
    clone.scene.traverse(obj => {
      if (obj.name) clone.nodes[obj.name] = obj;
      if (obj.material && !clone.materials[obj.material.name])
        clone.materials[obj.material.name] = obj.material;
    });
  }

  return clone;
};

export const clearClones = () => {
  // console.log("useGLTFLoader.cloneData");
  for (const url in cache) {
    if (
      Object.prototype.hasOwnProperty.call(cache, url) &&
      Object.prototype.hasOwnProperty.call(cache[url], "clone")
    ) {
      delete cache[url].clone;
    }
  }
};

const useGLTFLoader = url => {
  if (!url) {
    return null;
  }

  if (url in cache) {
    // If an error occurred,
    if (Object.prototype.hasOwnProperty.call(cache[url], "error")) {
      // console.log("useGLTFLoader: cache error");
      throw cache[url].error;
    }

    // If a response was successful,
    if (Object.prototype.hasOwnProperty.call(cache[url], "response")) {
      // If a clone already exists
      if (!Object.prototype.hasOwnProperty.call(cache[url], "clone")) {
        // console.log("useGLTFLoader: cache clone...");
        cache[url].clone = cloneData(cache[url].response);
      }

      // console.log("useGLTFLoader: cache response");
      return cache[url].clone;
    }

    // console.log("useGLTFLoader: throw cache promise");
    throw cache[url].promise;
  }

  // console.log("useGLTFLoader: build cache");
  cache[url] = {
    promise: new Promise((resolve, reject) => {
      // Load a glTF resource
      loader.load(
        // resource URL
        url,
        data => {
          resolve(data);
        },
        f => f,
        error => reject(error.message)
      );
    })
      .then(response => {
        cache[url] = {
          ...cache[url],
          response: response,
        };
      })
      .catch(error => {
        cache[url].error = error;
      }),
  };

  // console.log("useGLTFLoader: throw new promise");
  throw cache[url].promise;
};

export default useGLTFLoader;
