import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Container from "@components/Container";
import AnimatedText from "@components/AnimatedText";
import usePreloadedImage from "@utils/usePreloadedImage";

import "./style.css";

const DEFAULT_HEADER_COLOR = "auto";

const ProjectCover = ({ acf, entered, entering }) => {
  const projectName = acf?.projectName ?? "";
  const tagline = acf?.tagline ?? "";

  const imageUrl = acf?.image?.sourceUrl ?? "";
  const alternativeImageUrl = acf?.alternativeImage?.sourceUrl ?? imageUrl;

  const headerColor = acf?.projectCover_headerColor ?? DEFAULT_HEADER_COLOR;

  const textColor = acf?.projectCover_textColor ?? "white";

  const { isLoaded: isCoverImageLoaded } = usePreloadedImage(imageUrl);
  const { isLoaded: isCoverAlternativeImageLoaded } = usePreloadedImage(
    alternativeImageUrl
  );

  const isReady = isCoverImageLoaded && isCoverAlternativeImageLoaded;

  return (
    <div
      className={classnames("project-cover", `header-color--${headerColor}`, {
        "project-cover--loading": !isReady,
      })}
    >
      <Container
        variant="full"
        style={{
          "--cover-image": `url(${imageUrl})`,
          "--cover-image-mobile": `url(${alternativeImageUrl})`,
        }}
      >
        <div className="project-cover__heading">
          <h4 style={{ color: textColor ? textColor : "white" }}>
            <AnimatedText
              text={projectName}
              fadeIn={(entered || entering) && isReady}
              delay={240}
              blockDelay={2000}
              easing="var(--t--ease-out-quint)"
              duration={2000}
            />
          </h4>
          <h1 style={{ color: textColor }}>
            <AnimatedText
              text={tagline}
              fadeIn={(entered || entering) && isReady}
              delay={240}
              blockDelay={2240}
              easing="var(--t--ease-out-quint)"
              duration={2000}
            />
          </h1>
        </div>
      </Container>
    </div>
  );
};

ProjectCover.propTypes = {
  acf: PropTypes.object.isRequired,
  entered: PropTypes.bool,
};
ProjectCover.defaultProps = {
  entered: false,
};

export default ProjectCover;
