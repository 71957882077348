import { quadOut } from "eases";
import React, { useEffect, useRef, useCallback } from "react";
import useInView from "use-in-view";

const DEFAULT_DURATION = 4000;

const CountUp = props => {
  const {
    initialValue = 0,
    targetValue = 0,
    prefix,
    suffix,
    delay = 800,
    duration = DEFAULT_DURATION,
    easeFunction = quadOut,
  } = props;

  const [inViewRef, isInView] = useInView();

  const countElement = useRef(null);

  const animation = useRef({
    raf: null,
    progress: 0,
    startTime: null,
    initialValue,
    targetValue,
    delay,
    duration,
    easeFunction,
  });
  const value = useRef(initialValue);

  useEffect(() => {
    // update animation data on prop changes

    animation.current = {
      ...animation.current,
      initialValue,
      targetValue,
      delay,
      duration,
      easeFunction,
    };
  }, [initialValue, targetValue, delay, duration, easeFunction]);

  // make animation callback dependency free
  const animate = useCallback(currentTime => {
    if (!animation.current.startTime) {
      animation.current.startTime = currentTime;
    }

    const deltaTime = currentTime - animation.current.startTime;
    animation.current.progress = animation.current.easeFunction(
      Math.max(
        0,
        Math.min(
          (deltaTime - animation.current.delay) / animation.current.duration,
          1
        )
      )
    );

    value.current = Math.round(
      animation.current.initialValue +
        (animation.current.targetValue - animation.current.initialValue) *
          animation.current.progress
    );

    countElement.current.innerText = value.current;

    if (value.current < animation.current.targetValue) {
      animation.current.raf = requestAnimationFrame(animate);
    }
  }, []);

  useEffect(() => {
    if (isInView) {
      animation.current.raf = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(animation.current.raf);
  }, [isInView, animate]);

  return (
    <>
      {prefix && <span>{prefix}</span>}
      <span
        ref={ref => {
          countElement.current = ref;
          inViewRef(ref);
        }}
      >
        {value.current}
      </span>
      {suffix && <span>{suffix}</span>}
    </>
  );
};

export default CountUp;
