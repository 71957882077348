import { useMemo } from "react";
import { useThree } from "react-three-fiber";

export const visibleSizeAtZDepth = (depth, camera) => {
  // compensate for cameras not positioned at z=0
  const cameraOffset = camera.position.z;
  if (depth < cameraOffset) {
    depth -= cameraOffset;
  } else {
    depth += cameraOffset;
  }

  // vertical fov in radians
  const vFOV = (camera.fov * Math.PI) / 180;

  const height = 2 * Math.tan(vFOV / 2) * Math.abs(depth);

  return {
    height,
    width: height * camera.aspect,
  };
};

export const useVisibleSizeAtZDepth = depth => {
  const { camera } = useThree();

  return useMemo(
    () =>
      visibleSizeAtZDepth(depth, {
        fov: camera.fov,
        position: {
          z: camera.position.z,
        },
        aspect: camera.aspect,
      }),
    [depth, camera.fov, camera.position.z, camera.aspect]
  );
};

export default visibleSizeAtZDepth;
