import React from "react";

import { ReactComponent as FacebookIcon } from "@assets/images/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "@assets/images/ico_twitter.svg";
import { ReactComponent as LinkedinIcon } from "@assets/images/ico_linkedin.svg";
import { ReactComponent as DribbbleIcon } from "@assets/images/ico_dribbble.svg";
import { ReactComponent as InstagramIcon } from "@assets/images/ico_instagram.svg";
import classNames from "classnames";

import "./style.css";

const SOCIALS = {
  facebook: {
    label: "Facebook",
    Icon: FacebookIcon,
  },
  twitter: {
    label: "Twitter",
    Icon: TwitterIcon,
  },
  linkedin: {
    label: "LinkedIn",
    Icon: LinkedinIcon,
  },
  dribbble: {
    label: "Dribbble",
    Icon: DribbbleIcon,
  },
  instagram: {
    label: "Instagram",
    Icon: InstagramIcon,
  },
};

const Socials = ({ className, socials }) => {
  return (
    <div className={classNames("team-members-faces-socials", className)}>
      {socials?.map(({ social, link }, index) => {
        if (!SOCIALS[social]) {
          return null;
        }

        const Icon = SOCIALS[social].Icon;

        return (
          <a
            key={index}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="team-members-faces-socials__item"
          >
            <Icon />
          </a>
        );
      })}
    </div>
  );
};

export default Socials;
