import React, { Component, createRef } from "react";
import classnames from "classnames";

import Image from "@components/Image";
import InViewWrapper from "@components/InViewWrapper";

import ParallaxCarouselItem from "./ParallaxCarouselItem";

import "./style.css";

//image positions from right to left
//because weirdly, the grid has been designed from right to left
const positions = [
  {
    width: 7,
    height: 4,
    top: 3,
    left: 0,
    zIndex: 0,
  },
  {
    width: 4,
    height: 4,
    top: 0,
    left: -2,
    zIndex: 1,
  },
  {
    width: 4,
    height: 3,
    top: 8,
    left: 1,
    zIndex: 2,
  },
  {
    width: 5,
    height: 4,
    top: 5,
    left: -6,
    zIndex: 0,
  },
  {
    width: 4,
    height: 4,
    top: 8,
    left: -7,
    zIndex: 1,
  },
  {
    width: 6,
    height: 6,
    top: 2,
    left: -13,
    zIndex: 2,
  },
  {
    width: 4,
    height: 3,
    top: 0,
    left: -16,
    zIndex: 1,
  },
  {
    width: 7,
    height: 4,
    top: 7,
    left: -19,
    zIndex: 0,
  },
  {
    width: 5,
    height: 4,
    top: 0,
    left: -22,
    zIndex: 0,
  },
  {
    width: 5,
    height: 5,
    top: 2,
    left: -26,
    zIndex: 1,
  },
  {
    width: 4,
    height: 3,
    top: 6,
    left: -24,
    zIndex: 2,
  },
  {
    width: 4,
    height: 3,
    top: 9,
    left: -29,
    zIndex: 0,
  },
  {
    width: 8,
    height: 4,
    top: 1,
    left: -38,
    zIndex: 0,
  },
  {
    width: 5,
    height: 4,
    top: 6,
    left: -36,
    zIndex: 2,
  },
  {
    width: 6,
    height: 4,
    top: 8,
    left: -40,
    zIndex: 1,
  },
];

class ParallaxCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      offset: 0,
    };

    this.container = createRef();
    this.containerFractDelta = 0;

    this.isDrag = false;

    this.dragging = false;
    this.autoscrollEnabled = false;
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    document.addEventListener("visibilitychange", this.onVisibilityChange);
    if (this.container.current) {
      this.container.current.addEventListener("mousedown", this.onMouseDown);
      this.container.current.addEventListener("touchstart", this.onMouseDown);
      this.container.current.addEventListener("mouseup", this.onMouseUp);
      this.container.current.addEventListener("touchend", this.onMouseUp);
      this.container.current.addEventListener("touchmove", this.onTouchMove);
    }
    this.raf = requestAnimationFrame(this.update);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
    if (this.container.current) {
      this.container.current.removeEventListener("mousedown", this.onMouseDown);
      this.container.current.removeEventListener(
        "touchstart",
        this.onMouseDown
      );
      this.container.current.removeEventListener("mouseup", this.onMouseUp);
      this.container.current.removeEventListener("touchend", this.onMouseUp);
      this.container.current.removeEventListener("touchmove", this.onTouchMove);
    }

    cancelAnimationFrame(this.raf);
    clearInterval(this.isScrollingInterval);
  }

  componentDidUpdate() {
    this.backgroundImages = [...document.querySelectorAll(".zIndexBackground")];
    this.middleImages = [...document.querySelectorAll(".zIndexMiddle")];
    this.foregroundImages = [...document.querySelectorAll(".zIndexForeground")];
  }

  startScrollIfNeeded = () => {
    if (this.props.autoScroll) {
      let start = this.container.current.scrollLeft;
      this.isScrollingInterval = setInterval(() => {
        if (
          !this.container.current ||
          this.container.current.scrollLeft === start
        ) {
          clearInterval(this.isScrollingInterval);
          this.time = Date.now();
          this.autoscrollEnabled = true;
        } else {
          start = this.container.current.scrollLeft;
        }
      }, 100);
    }
  };

  onResize = event => {
    const images = [];

    this.containerSize = this.container.current.getBoundingClientRect();

    this.gutterToColumnRatio = 0.1;
    this.col_width =
      this.containerSize.height / (12 + this.gutterToColumnRatio * 11);
    this.gutter_width = this.gutterToColumnRatio * this.col_width;

    const src_images = this.props.images;
    let index = 0;

    //add more images until we have minimum 15
    while (src_images.length < 15) {
      src_images.push(this.props.images[index]);
      index++;
      if (index === this.props.images.length) {
        index = 0;
      }
    }

    //calculate the starting position, we have a pattern 15 images long
    const reminder = src_images.length % 15 || 15;

    //our pattern is reversed, the pattern is anchored at the end, so adding an image cause a new position to appear the the beginning
    //ex: pattern A B C D -> if we have 3 images we have B C D, if we add 1 image, we have A B C D
    let start = reminder - 1;

    //offset in columns
    let offset = -positions[start].left;

    src_images.forEach(({ url, title, year }) => {
      images.push({
        ...positions[start],
        title,
        year,
        src: url,
        left: this.getWidth(positions[start].left + offset) + this.gutter_width,
        width: this.getWidth(positions[start].width),
      });

      start--;
      if (start === -1) {
        start = 14;
        offset += 40;
      }
    });

    this.containerWidth = this.minLeft =
      images[14].left + images[14].width - this.containerSize.width;
    this.maxLeft = 2 * (images[14].left + images[14].width);

    this.setState({ images });

    this.startScrollIfNeeded();
  };

  onVisibilityChange = () => {
    if (document.hidden) {
      this.autoscrollEnabled = false;
    } else {
      this.startScrollIfNeeded();
    }
  };

  update = () => {
    if (this.container.current) {
      if (this.props.autoScroll && this.autoscrollEnabled) {
        this.autoScroll();
      }

      this.setParallax(this.container.current.scrollLeft);
    }

    requestAnimationFrame(this.update);
  };

  setParallax = left => {
    //check if we are at the edges
    if (left > this.maxLeft) {
      //we are at the end, go back to the beginning
      this.container.current.scrollLeft =
        this.minLeft + this.containerSize.width;
      left = this.container.current.scrollLeft;
    } else if (left < this.minLeft) {
      //we are at the beginning, go back to the end
      this.container.current.scrollLeft =
        this.maxLeft - this.containerSize.width;
      left = this.container.current.scrollLeft;
    }
    //move the three layers of images
    this.backgroundImages.forEach(img => {
      const img_left = parseFloat(img.style.left);
      img.style.transform = `translateX(${(img_left -
        left +
        this.containerFractDelta) /
        10}px)`;
    });
    this.middleImages.forEach(img => {
      const img_left = parseFloat(img.style.left);
      img.style.transform = `translateX(${(img_left -
        left +
        this.containerFractDelta) /
        5}px)`;
    });
    this.foregroundImages.forEach(img => {
      const img_left = parseFloat(img.style.left);
      img.style.transform = `translateX(${(img_left -
        left +
        this.containerFractDelta) /
        2.5}px)`;
    });
  };

  autoScroll = () => {
    const now = Date.now();
    const delta = (this.props.scrollSpeed * (now - this.time)) / 1000;

    const integerDelta = parseInt(this.containerFractDelta + delta);
    const fractDelta = this.containerFractDelta + delta - integerDelta;

    const nextLeft =
      this.container.current.scrollLeft + integerDelta + fractDelta;
    if (nextLeft > this.maxLeft) {
      const newIntegerDelta = parseInt(nextLeft - this.maxLeft);
      const newFractDelta = nextLeft - this.maxLeft - newIntegerDelta;
      this.container.current.scrollLeft =
        this.minLeft + this.containerSize.width + newIntegerDelta;
      this.containerFractDelta = newFractDelta;
    } else if (nextLeft < this.minLeft) {
      const newIntegerDelta = parseInt(nextLeft - this.minLeft);
      const newFractDelta = nextLeft - this.minLeft - newIntegerDelta;
      this.container.current.scrollLeft =
        this.maxLeft - this.containerSize.width + newIntegerDelta;
      this.containerFractDelta = newFractDelta;
    } else {
      this.container.current.scrollLeft += integerDelta;
      this.containerFractDelta = fractDelta;
    }

    this.time = now;
  };

  getWidth = columns => {
    return (
      this.col_width * (columns + this.gutterToColumnRatio * (columns - 1))
    );
  };

  onTouchMove = event => {
    this.isDrag = true;
  };

  onMouseDown = event => {
    this.isDrag = false;
    clearInterval(this.isScrollingInterval);
    const touch = event.type === "touchstart";
    const x = touch
      ? event.touches[0] && event.touches[0].clientX
      : event.clientX;
    this.dragging = true;
    this.autoscrollEnabled = false;
    this.prevMouseX = x;
    if (this.container.current && !touch) {
      this.container.current.addEventListener("mousemove", this.onMouseMove);
    }
  };

  onMouseUp = event => {
    if (this.isDrag) {
      event.preventDefault();
    }
    const touch = event.type === "touchend";
    if (touch) {
      event.preventDefault();
      if (!this.isDrag) {
        this.handleOverlay(event);
      }
    }
    this.dragging = false;
    if (this.container.current && !touch) {
      this.container.current.removeEventListener("mousemove", this.onMouseMove);
    }
    this.startScrollIfNeeded();
  };

  onMouseMove = event => {
    this.isDrag = true;

    this.container.current.scrollLeft += this.prevMouseX - event.clientX;

    this.prevMouseX = event.clientX;
  };

  onClick = event => {
    if (this.isDrag) {
      event.preventDefault();
    } else {
      this.handleOverlay(event);
    }
  };

  handleOverlay = event => {
    if (this.props.onImageClick) {
      if (
        event.target.classList &&
        event.target.classList.contains("parallax-carousel-item__image")
      ) {
        this.props.onImageClick(event.target);
        clearInterval(this.isScrollingInterval);
      }
    }
  };

  render() {
    const { images } = this.state;
    const { headerColor } = this.props;

    return (
      <InViewWrapper>
        {isInView => (
          <div
            className={classnames(
              "parallax-container",
              `header-color--${headerColor}`,
              {
                "parallax-container--active": isInView,
              }
            )}
            style={{ background: "#000000" }}
            ref={this.container}
            onClick={this.onClick}
            role="presentation"
          >
            {images.map((img, index) => {
              return (
                <ParallaxCarouselItem
                  key={`${index}-${img.src}`}
                  index={-(index + 1)}
                  src={img.src}
                  title={img.title}
                  year={img.year}
                  className={classnames({
                    zIndexForeground: img.zIndex === 2,
                    zIndexMiddle: img.zIndex === 1,
                    zIndexBackground: img.zIndex === 0,
                  })}
                  style={{
                    zIndex: img.zIndex,
                    left: `${img.left}px`,
                    top: `${(100 * img.top) / 12}%`,
                    width: `${img.width}px`,
                    height: `${(100 * img.height) / 12}%`,
                    // transitionDelay: `${Math.random()}s`,
                  }}
                />
              );
            })}
            {images.map((img, index) => {
              return (
                <ParallaxCarouselItem
                  key={`${index}-${img.src}`}
                  index={index + 1}
                  src={img.src}
                  title={img.title}
                  year={img.year}
                  className={classnames({
                    zIndexForeground: img.zIndex === 2,
                    zIndexMiddle: img.zIndex === 1,
                    zIndexBackground: img.zIndex === 0,
                  })}
                  style={{
                    zIndex: img.zIndex,
                    left: `${img.left + images[14].left + images[14].width}px`,
                    top: `${(100 * img.top) / 12}%`,
                    width: `${img.width}px`,
                    height: `${(100 * img.height) / 12}%`,
                    // transitionDelay: `${Math.random()}s`,
                  }}
                />
              );
            })}
            {images.map((img, index) => {
              return (
                <ParallaxCarouselItem
                  key={`${index}-${img.src}`}
                  index={index + 1 + 15}
                  src={img.src}
                  title={img.title}
                  year={img.year}
                  className={classnames({
                    zIndexForeground: img.zIndex === 2,
                    zIndexMiddle: img.zIndex === 1,
                    zIndexBackground: img.zIndex === 0,
                  })}
                  style={{
                    zIndex: img.zIndex,
                    left: `${img.left +
                      2 * (images[14].left + images[14].width)}px`,
                    top: `${(100 * img.top) / 12}%`,
                    width: `${img.width}px`,
                    height: `${(100 * img.height) / 12}%`,
                    // transitionDelay: `${Math.random()}s`,
                  }}
                />
              );
            })}
          </div>
        )}
      </InViewWrapper>
    );
  }
}

ParallaxCarousel.defaultProps = {
  autoScroll: false,
  scrollSpeed: 20,
};

export default ParallaxCarousel;
