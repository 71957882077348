import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./style.css";

const Container = ({ className, variant, ...props }) => {
  const marginClasses = classnames("container-margin", className, {
    "container-margin--full": variant === "full",
    "container-margin--large": variant === "large",
    "container-margin--small": variant === "small",
  });

  const classes = classnames("container", className, {
    "container--full": variant === "full",
    "container--large": variant === "large",
    "container--small": variant === "small",
  });

  return (
    <div className={marginClasses}>
      <div className={classes} {...props} />
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default Container;
