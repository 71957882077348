import transformObj from "@utils/transformObj";

const TYPE_NAME = "WPGraphQL";
// const FIELD_NAME = "wpgraphql";

const prefixTypename = (data, prefix) => {
  return transformObj(data, (key, value) => {
    if (key === "__typename") {
      return `${prefix}_${value}`;
    }

    return value;
  });
};

const buildPreviewDataObject = (key, preview) => {
  const previewData = {
    // get latest revision content
    ...preview[key].preview.nodes[0],

    // use `blocks` as fallback when there is no `previewData available`
    blocks:
      preview[key].blocksPreview && preview[key].blocksPreview.length
        ? preview[key].blocksPreview
        : preview[key].blocks,
  };

  return prefixTypename(previewData, TYPE_NAME);
};

export default (key, { preview, data }) => {
  return preview
    ? buildPreviewDataObject(key, preview)
    : // return published post data if no preview is available
      data.wpgraphql[key];
};
