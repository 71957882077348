import classnames from "classnames";
import React from "react";
import useInView from "use-in-view";

import "./style.css";

const FadeInOnView = ({ className, children, translate }) => {
  const [ref, isInView] = useInView();

  return (
    <div
      ref={ref}
      className={classnames(
        "fade-in-on-view",
        {
          "fade-in-on-view--active": isInView,
          "fade-in-on-view--translate-none": translate === "none",
          "fade-in-on-view--translate-small": translate === "small",
          "fade-in-on-view--translate-medium": translate === "medium",
          "fade-in-on-view--translate-large": translate === "large",
        },
        className
      )}
    >
      {typeof children === "function" ? children(isInView) : children}
    </div>
  );
};

FadeInOnView.defaultProps = {
  translate: "small",
};

export default FadeInOnView;
