import { useEffect } from "react";

const useUIColorCheck = (deps = []) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent("checkHeaderColor"));
      window.dispatchEvent(new CustomEvent("checkSideNavColor"));
    }, 20);
  }, deps);
};

export default useUIColorCheck;
