import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Container from "@components/Container";
import FadeInOnView from "@components/FadeInOnView";

import "./style.css";

const DEFAULT_TEXT_COLOR = "black";
const DEFAULT_BACKGROUND_COLOR = "white";

const DEFAULT_HEADER_COLOR = "auto";
const DEFAULT_MARGIN_SETTING = "normal";

const TechFeatures = ({ acf }) => {
  const title = acf?.title ?? "";

  const color = acf?.techFeatures_textColor ?? DEFAULT_TEXT_COLOR;
  const background =
    acf?.techFeatures_backgroundColor ?? DEFAULT_BACKGROUND_COLOR;

  const firstFeatureText = acf?.features?.column_1 ?? "";
  const secondFeatureText = acf?.features?.column_2 ?? "";
  const thirdFeatureText = acf?.features?.column_3 ?? "";

  const headerColor = acf?.techFeatures_headerColor ?? DEFAULT_HEADER_COLOR;
  const marginTop = acf?.techFeatures_marginTop ?? DEFAULT_MARGIN_SETTING;
  const marginBottom = acf?.techFeatures_marginBottom ?? DEFAULT_MARGIN_SETTING;

  return (
    <FadeInOnView
      className={classnames(
        "tech-features",
        `header-color--${headerColor}`,
        `margin-top--${marginTop}`,
        `margin-bottom--${marginBottom}`
      )}
      style={{
        background,
        color,
      }}
    >
      <Container variant="small">
        {title && <h4 className="tech-features__heading">{title}</h4>}

        <p className="tech-features__wrapper">
          <span>{firstFeatureText}</span>
          <span>{secondFeatureText}</span>
          <span>{thirdFeatureText}</span>
        </p>
      </Container>
    </FadeInOnView>
  );
};

TechFeatures.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default TechFeatures;
