import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import useInView from "use-in-view";

import Container from "@components/Container";
import Image from "@components/Image";
import Video from "@components/Video";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";
const DEFAULT_HEADER_COLOR = "auto";
const DEFAULT_MARGIN_SETTING = "normal";

const BannerImageThree = ({ acf }) => {
  const [ref, isInView] = useInView();

  const leftMediaUrl = acf?.leftMedia?.mediaItemUrl ?? "";
  const leftMediaType = acf?.leftMedia?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const middleMediaUrl = acf?.middleMedia?.mediaItemUrl ?? "";
  const middleMediaType = acf?.middleMedia?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const rightMediaUrl = acf?.rightMedia?.mediaItemUrl ?? "";
  const rightMediaType = acf?.rightMedia?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const LeftMedia = leftMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const CenterMedia = middleMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const RightMedia = rightMediaType === DEFAULT_MEDIA_TYPE ? Image : Video;

  const headerColor = acf?.bannerImageThree_headerColor ?? DEFAULT_HEADER_COLOR;
  const marginTop = acf?.bannerImageThree_marginTop ?? DEFAULT_MARGIN_SETTING;
  const marginBottom =
    acf?.bannerImageThree_marginBottom ?? DEFAULT_MARGIN_SETTING;

  return (
    <div
      ref={ref}
      className={classnames(
        "banner-image-three",
        `header-color--${headerColor}`,
        `margin-top--${marginTop}`,
        `margin-bottom--${marginBottom}`,
        {
          "banner-image-three--active": isInView,
        }
      )}
    >
      <Container variant="full">
        <div className="section">
          <LeftMedia src={leftMediaUrl} objectPosition="center" autoPlay loop />
        </div>
        <div className="section">
          <CenterMedia
            src={middleMediaUrl}
            objectPosition="center"
            autoPlay
            loop
          />
        </div>
        <div className="section">
          <RightMedia
            src={rightMediaUrl}
            objectPosition="center"
            autoPlay
            loop
          />
        </div>
      </Container>
    </div>
  );
};

BannerImageThree.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default BannerImageThree;
