import React from "react";
import classnames from "classnames";
import Image from "@components/Image";

const ParallaxCarouselItem = ({
  index,
  className,
  src,
  title,
  year,
  onImageClick,
  ...props
}) => {
  let label = title;
  if (year) {
    label = `${label} – ${year}`;
  }

  return (
    <div className={classnames("parallax-carousel-item", className)} {...props}>
      <div className="parallax-carousel-item__label">{label}</div>
      <Image
        data-index={index}
        className={"parallax-carousel-item__image"}
        src={src}
        onClick={onImageClick}
      />
    </div>
  );
};

export default ParallaxCarouselItem;
