import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Grid from "@components/Grid";

import Slider from "./Slider";
import "./style.css";
import classNames from "classnames";

const PlaygroundDetail = ({ items, activeIndex, onDismiss = f => f }) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const {
    title = "No Title",
    year = null,
    description = "No Description",
    link = null,
    images = [],
  } = activeIndex >= 0 ? items[activeIndex] : {};

  useEffect(() => {
    if (activeIndex === -1) {
      document.body.classList.remove("playground-detail-open");
    } else {
      document.body.classList.add("playground-detail-open");
    }
  }, [activeIndex]);

  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (activeIndex === -1) {
      setIsReady(false);

      return;
    }

    // get source image position and size
    const sourceImage = document.getElementById(
      "playground-detail-active-item"
    );

    const sourceBcr = sourceImage.getBoundingClientRect();

    // create new image overlaying source image with same width and height
    const overlayImage = document.createElement("div");
    overlayImage.style.width = `${sourceBcr.width}px`;
    overlayImage.style.height = `${sourceBcr.height}px`;
    overlayImage.style.position = "fixed";
    overlayImage.style.top = `${sourceBcr.y}px`;
    overlayImage.style.left = `${sourceBcr.x}px`;
    overlayImage.style.zIndex = 2000;
    overlayImage.style.backgroundImage = `url(${sourceImage.src})`;
    overlayImage.style.backgroundSize = "cover";
    overlayImage.style.backgroundPosition = "center";
    overlayImage.style.transition = "all 0.8s cubic-bezier(0.23, 1, 0.32, 1)";

    document.body.appendChild(overlayImage);

    // get target image position and size
    const targetImage = document.querySelector(
      ".playground-detail-slider__item--active"
    );

    // animate overlay image to target image position and size
    const targetBcr = targetImage.getBoundingClientRect();

    overlayImage.style.top = `${targetBcr.y}px`;
    overlayImage.style.left = `${targetBcr.x}px`;
    overlayImage.style.width = `${targetBcr.width}px`;
    overlayImage.style.height = `${targetBcr.height}px`;

    const timeout = setTimeout(() => {
      setIsReady(true);
      document.body.removeChild(overlayImage);
    }, 800);

    return () => {
      clearTimeout(timeout);
      if (overlayImage && overlayImage.parentNode) {
        overlayImage.parentNode.removeChild(overlayImage);
      }
    };
  }, [activeIndex]);

  if (!isMounted) {
    return null;
  }

  return createPortal(
    <TransitionGroup>
      {activeIndex >= 0 && (
        <CSSTransition
          key={activeIndex}
          timeout={800}
          classNames="playground-detail-"
        >
          <div
            className={classNames("playground-detail", {
              "playground-detail--ready": isReady,
            })}
          >
            <Grid.Container narrowMargins className="playground-detail__inner">
              <button
                className="playground-detail__dismiss"
                onClick={onDismiss}
              />
              <Grid.Row className="playground-detail__slides">
                <Grid.Cell mobileColumns={4} columns={10}>
                  <Slider images={images} />
                </Grid.Cell>
              </Grid.Row>

              <Grid.Row className="playground-detail__content">
                <Grid.Cell
                  mobileColumns={4}
                  columns={4}
                  className="playground-detail__title-wrapper"
                >
                  <h2 className="playground-detail__title">{title}</h2>
                  {year && (
                    <div className="playground-detail__year">– {year}</div>
                  )}
                </Grid.Cell>

                <Grid.Cell
                  mobileColumns={4}
                  columns={6}
                  className="playground-detail__description-wrapper"
                >
                  <div
                    className="playground-detail__description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  {link && (
                    <a
                      className="playground-detail__link"
                      href={link.url}
                      target={link.target}
                    >
                      {link.title}
                    </a>
                  )}
                </Grid.Cell>
              </Grid.Row>
            </Grid.Container>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>,
    document.body
  );
};

export default PlaygroundDetail;
