import React, { useCallback } from "react";
import ReactDatGui, { DatFolder, DatNumber, DatBoolean } from "react-dat-gui";
import "react-dat-gui/dist/index.css";
import create from "zustand";

export const [useConfig] = create(set => ({
  camera: {
    position: {
      x: 0,
      y: 0,
      z: 400,
    },
    near: 0.01,
    far: 1000,
    fov: 70,
    isOrbitControlsEnabled: false,
  },
  fog: {
    isEnabled: false,
    near: 200,
    far: 300,
  },
  contactMaterial: {
    wireframe: false,
    // friction: 0.3,
    // restitution: 0.3,
    // contactEquationStiffness: 1e6,
    // _contactEquationStiffnessPow: 6,
    // contactEquationRelaxation: 3,
    // frictionEquationStiffness: 1e7,
    // _frictionEquationStiffnessPow: 7,
    // frictionEquationRelaxation: 3,
    friction: 0.3,
    restitution: 0.1,
    contactEquationStiffness: 1e8,
    _contactEquationStiffnessPow: 6,
    contactEquationRelaxation: 3,
    frictionEquationStiffness: 1e7,
    _frictionEquationStiffnessPow: 7,
    frictionEquationRelaxation: 3,
  },
  set,
}));

export const DatGui = () => {
  const data = useConfig();
  const set = useConfig(config => config.set);
  const handleUpdate = useCallback(
    d => {
      set({
        ...d,
        contactEquationStiffness: Math.pow(10, d._contactEquationStiffnessPow),
        frictionEquationStiffness: Math.pow(
          10,
          d._frictionEquationStiffnessPow
        ),
      });
    },
    [set]
  );

  return (
    <ReactDatGui data={data} onUpdate={handleUpdate}>
      <DatFolder title="Camera">
        <DatBoolean
          path="camera.isOrbitControlsEnabled"
          label="Orbit Controls"
        />
        <DatFolder title="Position">
          <DatNumber
            path="camera.position.x"
            label="x"
            min={-500}
            max={500}
            step={1}
          />
          <DatNumber
            path="camera.position.y"
            label="y"
            min={-500}
            max={500}
            step={1}
          />
          <DatNumber
            path="camera.position.z"
            label="z"
            min={-500}
            max={500}
            step={1}
          />
        </DatFolder>
      </DatFolder>
      <DatFolder title="Fog">
        <DatBoolean path="fog.isEnabled" label="Enable" />
        <DatNumber path="fog.near" label="Near" min={0} max={1000} step={1} />
        <DatNumber path="fog.far" label="Far" min={0} max={1000} step={1} />
      </DatFolder>
      <DatFolder title="Contact Material">
        <DatBoolean path="contactMaterial.wireframe" label="Show" />
        <DatNumber
          path="contactMaterial.friction"
          label="Friction"
          min={0}
          max={10}
          step={0.01}
        />
        <DatNumber
          path="contactMaterial.restitution"
          label="Restitution"
          min={0}
          max={10}
          step={0.01}
        />
        <DatNumber
          path="contactMaterial._contactEquationStiffnessPow"
          label="Contact equation stiffness"
          min={0}
          max={10}
          step={1}
        />
        <DatNumber
          path="contactMaterial.contactEquationRelaxation"
          label="Contact equation relaxation"
          min={0}
          max={100}
          step={0.1}
        />
        <DatNumber
          path="contactMaterial._frictionEquationStiffnessPow"
          label="Friction equation stiffness"
          min={0}
          max={10}
          step={1}
        />
        <DatNumber
          path="contactMaterial.frictionEquationRelaxation"
          label="Friction equation relaxation"
          min={0}
          max={100}
          step={0.1}
        />
      </DatFolder>
    </ReactDatGui>
  );
};
