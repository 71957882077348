import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import CountUp from "@components/CountUp";
import FadeInOnView from "@components/FadeInOnView";
import Grid from "@components/Grid";

import "./style.css";

const DEFAULT_HEADER_COLOR = "auto";

const Awards = ({ acf }) => {
  const title = acf?.title ?? "";
  const description = acf?.description ?? "";

  const textColor = acf?.textColor ?? "#000000";
  const backgroundColor = acf?.backgroundColor ?? "#ffffff";

  const headerColor = acf?.stats_headerColor ?? DEFAULT_HEADER_COLOR;

  return (
    <Grid.Container
      narrowMargins
      className={classnames("awards", `header-color--${headerColor}`)}
      style={{
        color: textColor,
        backgroundColor: backgroundColor,
      }}
    >
      <Grid.Row>
        <Grid.Cell
          mobileColumns={4}
          columns={7}
          offset={0}
          className="awards__intro"
        >
          <h2 className="awards__title">{title}</h2>
          <div
            className="awards__description"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Grid.Cell>
      </Grid.Row>

      <Grid.Row>
        <Grid.Cell mobileColumns={4} columns={8} offset={2}>
          <div className="awards__list">
            {acf?.awards?.map((award, index) => (
              <FadeInOnView
                key={index}
                translate="medium"
                className="awards__item"
              >
                <div className="awards__item-icon">
                  <img
                    src={award?.icon?.mediaItemUrl}
                    width={award?.icon?.mediaDetails?.width / 2}
                    height={award?.icon?.mediaDetails?.height / 2}
                    alt={award.name}
                  />
                </div>

                <div className="awards__item-count">
                  <CountUp targetValue={award.count}>0</CountUp>
                </div>
              </FadeInOnView>
            ))}
          </div>
        </Grid.Cell>
      </Grid.Row>
    </Grid.Container>
  );
};

Awards.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default Awards;
