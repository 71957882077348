import classnames from "classnames";
import React, { useEffect, useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import useInView from "use-in-view";

import Container from "@components/Container";
import Image from "@components/Image";
import Video from "@components/Video";

import { laptop } from "@styles/breakpoints";
import clamp from "@utils/clamp";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";

const DEFAULT_TEXT_COLOR = "black";
const DEFAULT_BACKGROUND_COLOR = "white";

const DEFAULT_MARGIN_SETTING = "normal";

const DEFAULT_ASPECT_RATIO_SETTING = "portrait";

const DEFAULT_HEADER_COLOR = "auto";

const SWIPE_THRESHOLD = 70;
const DRAG_THRESHOLD = 5;

const isMobile = () => window.innerWidth < laptop;

const getTouchX = event =>
  (event.touches && event.touches[0] && event.touches[0].clientX) ||
  event.clientX;

const MediaGallery = ({ acf }) => {
  const [isMobileCarousel, setIsMobileCarousel] = useState(false);

  const [ref, isInView] = useInView();

  const title = acf?.title ?? "";
  const gallery = acf?.gallery ?? [];

  const textColor = acf?.mediaTextGallery_textColor ?? DEFAULT_TEXT_COLOR;
  const backgroundColor =
    acf?.mediaTextGallery_backgroundColor ?? DEFAULT_BACKGROUND_COLOR;

  const classes = classnames(
    "media-gallery",
    `margin-top--${acf?.mediaGallery_marginTop ?? DEFAULT_MARGIN_SETTING}`,
    `margin-bottom--${acf?.mediaGallery_marginBottom ??
      DEFAULT_MARGIN_SETTING}`,
    `header-color--${acf?.mediaGallery_headerColor ?? DEFAULT_HEADER_COLOR}`,
    {
      "media-gallery--active": isInView,
    }
  );

  const slider = useRef();
  const firstSlide = useRef(null);
  const secondSlide = useRef(null);

  const isDragging = useRef(false);
  const startX = useRef();
  const translateX = useRef(0);

  let raf = null;

  let momentum = false;

  let startScrollLeft;
  let startDate;

  const getCoursorX = event => event.pageX - slider.current.offsetLeft;

  const onTouchStart = useCallback(event => {
    isDragging.current = true;
    startX.current = getTouchX(event);

    const first = slider.current.querySelector(
      ".media-gallery__position--first"
    );
    const second = slider.current.querySelector(
      ".media-gallery__position--second"
    );

    first.classList.add("no-transition");
    firstSlide.current = first;

    if (second) {
      second.classList.add("no-transition");
      secondSlide.current = second;
    }
  }, []);

  const onTouchEnd = useCallback(() => {
    isDragging.current = false;

    const direction = translateX.current < 0 ? "left" : "right";

    const sliderChildren = [...slider.current.children];
    const firstIdx = sliderChildren.findIndex(node =>
      node.classList.contains("media-gallery__position--first")
    );

    // if the index of the first element is 0
    // set the 0th element to be the last item of the array
    const zero =
      firstIdx === 0
        ? sliderChildren[sliderChildren.length - 1]
        : sliderChildren[firstIdx - 1];
    const first = sliderChildren[firstIdx];
    const second = sliderChildren[(firstIdx + 1) % sliderChildren.length];
    const third = sliderChildren[(firstIdx + 2) % sliderChildren.length];
    const fourth = sliderChildren[(firstIdx + 3) % sliderChildren.length];

    first.classList.remove("no-transition");
    if (second) {
      second.classList.remove("no-transition");
    }

    if (
      Math.abs(translateX.current) < SWIPE_THRESHOLD ||
      (direction === "right" && !zero) ||
      (direction === "left" && !second)
    ) {
      translateX.current = 0;
    } else {
      if (direction === "left") {
        first.classList.remove("media-gallery__position--first");
        first.classList.add("media-gallery__position--hidden-left");

        if (second) {
          second.classList.remove("media-gallery__position--second");
          second.classList.add("media-gallery__position--first");
        }

        if (third) {
          third.classList.remove("media-gallery__position--third");
          third.classList.add("media-gallery__position--second");
        }

        if (fourth) {
          fourth.style.opacity = 1;
          fourth.classList.remove("media-gallery__position--hidden-right");
          fourth.classList.add("media-gallery__position--third");
        }

        if (zero) {
          // on swipe the 0th element disappears on the left and moves on the right
          zero.style.opacity = 0;
          zero.classList.remove("media-gallery__position--hidden-left");
          zero.classList.add("media-gallery__position--hidden-right");
        }
      } else {
        first.classList.remove("media-gallery__position--first");
        first.classList.add("media-gallery__position--second");

        if (zero) {
          // when swiping left for the first time the 0th item is hidden on the right
          zero.classList.contains("media-gallery__position--hidden-right") &&
            zero.classList.remove("media-gallery__position--hidden-right");

          zero.style.opacity = 1;
          zero.classList.remove("media-gallery__position--hidden-left");
          zero.classList.add("media-gallery__position--first");
        }

        if (second) {
          second.classList.remove("media-gallery__position--second");
          second.classList.add("media-gallery__position--third");
        }

        if (third) {
          third.classList.remove("media-gallery__position--third");
          third.classList.add("media-gallery__position--hidden-right");
        }

        if (fourth) {
          // on swipe the 4th element disappears on the right and moves on the left
          fourth.style.opacity = 0;
          fourth.classList.remove("media-gallery__position--hidden-right");
          fourth.classList.add("media-gallery__position--hidden-left");
        }
      }
      translateX.current = 0;
    }
    first.style.cssText = "";
    if (second) {
      second.style.cssText = "";
    }
  }, []);

  const onTouchMove = useCallback(event => {
    if (!isDragging.current) {
      return;
    }

    const x = getTouchX(event);
    translateX.current -= startX.current - x;

    if (Math.abs(translateX.current) < DRAG_THRESHOLD) {
      return;
    }

    event.preventDefault();

    const rotateFirst = clamp(
      4 * (translateX.current / (event.currentTarget.offsetWidth / 4)),
      -4,
      4
    );
    firstSlide.current.style.transform = `translate(${translateX.current}px, 0) rotate(${rotateFirst}deg)`;

    if (secondSlide) {
      const rotateSecond = clamp(
        2 * (translateX.current / (event.currentTarget.offsetWidth / 4)),
        -2,
        2
      );
      secondSlide.current.style.transform = `translate(${translateX.current /
        2 +
        50}px, 50px) rotate(${rotateSecond}deg)`;
    }

    startX.current = x;
  }, []);

  const onMouseDown = event => {
    isDragging.current = true;
    startX.current = getCoursorX(event);
    startDate = new Date();
    startScrollLeft = slider.current.scrollLeft;
  };

  const onMouseUpLeave = () => {
    if (!isDragging.current) {
      return;
    }
    isDragging.current = false;

    const direction = slider.current.scrollLeft >= startScrollLeft ? 1 : -1;
    const movement = Math.abs(startScrollLeft - slider.current.scrollLeft);
    let duration = new Date() - startDate;
    let speed = movement / duration;

    let scroll = slider.current.scrollLeft;
    let tick = performance.now();
    const animate = time => {
      if (momentum && speed > 0.0001) {
        scroll += speed * direction * (time - tick);
        slider.current.scrollLeft = scroll;
        speed *= 0.95;
        requestAnimationFrame(animate);
        tick = time;
      } else {
        momentum = false;
        startScrollLeft = slider.current.scrollLeft;
        cancelAnimationFrame(raf);
      }
    };

    momentum = true;
    animate(tick);
  };

  const onMouseMove = event => {
    if (
      !isDragging.current ||
      slider.current.offsetWidth <
        slider.current.parentNode.parentNode.offsetWidth
    ) {
      return;
    }
    event.preventDefault();

    momentum = false;

    const currX = getCoursorX(event);
    const movement = currX - startX.current;

    slider.current.scrollLeft = startScrollLeft - movement;
  };

  const position = {
    0: "first",
    1: "second",
    2: "third",
  };

  let events;
  if (isMobileCarousel) {
    events = {
      onMouseDown: onTouchStart,
      onMouseUp: onTouchEnd,
      onMouseLeave: onTouchEnd,
      onMouseMove: onTouchMove,
    };
  } else {
    events = {
      onMouseDown: onMouseDown,
      onMouseUp: onMouseUpLeave,
      onMouseLeave: onMouseUpLeave,
      onMouseMove: onMouseMove,
    };
  }

  useEffect(() => {
    const onResize = () => {
      const newIsMobile = isMobile();
      if (newIsMobile !== isMobileCarousel) {
        setIsMobileCarousel(newIsMobile);
      }
    };
    onResize();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  useEffect(() => {
    const addEvents = () => {
      slider.current.addEventListener("touchstart", onTouchStart);
      slider.current.addEventListener("touchend", onTouchEnd);
      slider.current.addEventListener("touchmove", onTouchMove);
    };
    const removeEvents = () => {
      slider.current.removeEventListener("touchstart", onTouchStart);
      slider.current.removeEventListener("touchend", onTouchEnd);
      slider.current.removeEventListener("touchmove", onTouchMove);
    };

    if (isMobileCarousel) {
      addEvents();
    } else {
      removeEvents();
    }
    return removeEvents;
  }, [isMobileCarousel, onTouchStart, onTouchEnd, onTouchMove]);

  // NOTE: add onscroll event + check safari & firefox
  return (
    <div
      ref={ref}
      className={classes}
      style={{
        color: textColor,
        backgroundColor: backgroundColor,
      }}
    >
      <Container className="media-gallery__heading" variant="small">
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
      </Container>
      <Container
        className={classnames("media-gallery__carousel", "cursor-type", {
          "cursor-type--drag":
            slider?.current?.offsetWidth ===
            slider?.current?.parentNode?.parentNode?.offsetWidth,
        })}
        variant="full"
      >
        <ul ref={slider} role="presentation" {...events}>
          {gallery.map((item, idx) => {
            const mediaUrl = item?.media?.file?.mediaItemUrl ?? "";
            const mediaType =
              item?.media?.file?.mediaType ?? DEFAULT_MEDIA_TYPE;
            const aspectRatio =
              item?.media?.aspectRatio ?? DEFAULT_ASPECT_RATIO_SETTING;
            const Media = mediaType === DEFAULT_MEDIA_TYPE ? Image : Video;

            const videoProps = {
              autoPlay: mediaType !== DEFAULT_MEDIA_TYPE ? true : undefined,
              muted: mediaType !== DEFAULT_MEDIA_TYPE ? true : undefined,
            };
            return (
              <li
                key={idx}
                className={classnames(
                  "media-gallery__position",
                  `media-gallery__position--${position[idx] ?? "hidden-right"}`
                )}
              >
                <Media
                  className={classnames(
                    "media-gallery__media",
                    `media-gallery__media--${aspectRatio}`
                  )}
                  src={mediaUrl}
                  objectPosition="center"
                  {...videoProps}
                />
              </li>
            );
          })}
        </ul>
      </Container>
    </div>
  );
};

MediaGallery.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default MediaGallery;
