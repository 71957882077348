import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import Container from "@components/Container";
import Image from "@components/Image";
import Video from "@components/Video";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";
const DEFAULT_HEADER_COLOR = "auto";

const BannerFullWidth = ({ acf }) => {
  const mediaUrl = acf?.media?.mediaItemUrl ?? "";
  const mediaType = acf?.media?.mediaType ?? DEFAULT_MEDIA_TYPE;
  const Media = mediaType === DEFAULT_MEDIA_TYPE ? Image : Video;
  const headerColor = acf?.bannerFull_headerColor ?? DEFAULT_HEADER_COLOR;
  const mediaFit = acf?.imageFit ?? "cover";

  return (
    <div
      className={classnames(
        "banner-full-width",
        `header-color--${headerColor}`,
        `media-fit--${mediaFit}`
      )}
    >
      <Container variant="full">
        <Media src={mediaUrl} objectPosition="center" autoPlayInView loop />
      </Container>
    </div>
  );
};

BannerFullWidth.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default BannerFullWidth;
