// this prevents any overhead from creating the object each time
let element = null;

export default encodedText => {
  if (typeof window === "undefined") {
    return encodedText;
  }

  if (element === null) {
    element = document.createElement("div");
  }

  let decodedText = encodedText;

  if (decodedText && typeof decodedText === "string") {
    // strip script/html tags
    decodedText = decodedText.replace(
      /<script[^>]*>([\S\s]*?)<\/script>/gim,
      ""
    );
    decodedText = decodedText.replace(
      /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
      ""
    );
    element.innerHTML = decodedText;
    decodedText = element.textContent;
    element.textContent = "";
  }

  return decodedText;
};
