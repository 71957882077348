import React, { Component, createRef } from "react";
import FadeInOnView from "@components/FadeInOnView";

import "./style.css";

class ServicesAnimatedText extends Component {
  constructor(props) {
    super(props);

    this.servicesRef = createRef();

    const servicesText = props.services;

    this.state = {
      services: servicesText,
    };
  }

  static defaultProps = {};

  setupCredits = () => {
    let servicesText = "";
  };

  onWindowResize = () => {
    this.resizeTimeout && clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(this.setupCredits, 100);
  };

  componentDidMount() {
    this.setupCredits();
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  render() {
    const { services } = this.state;

    return (
      <FadeInOnView className="project-services" translate="none">
        <div className="project-services__marquee" ref={this.servicesRef}>
          <div>
            <span>{services}</span>
            <span>{services}</span>
          </div>
        </div>
      </FadeInOnView>
    );
  }
}

export default ServicesAnimatedText;
