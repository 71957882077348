export default {
  WPGraphQL_CoreParagraphBlock: require("@blocks/ParagraphCenter"),
  WPGraphQL_AcfBannerFullWidthBlock: require("@blocks/BannerFullWidth"),
  WPGraphQL_AcfProjectCoverBlock: require("@blocks/ProjectCover"),
  WPGraphQL_AcfBannerImageThreeBlock: require("@blocks/BannerImageThree"),
  WPGraphQL_AcfMediaTextBlock: require("@blocks/MediaText"),
  WPGraphQL_AcfMediaFrameBlock: require("@blocks/MediaFrame"),
  WPGraphQL_AcfQuotesBlock: require("@blocks/Quotes"),
  WPGraphQL_AcfStatsBlock: require("@blocks/Stats"),
  WPGraphQL_AcfProjectIntroTextBlock: require("@blocks/ProjectIntroText"),
  WPGraphQL_AcfTitleTextBlock: require("@blocks/TitleText"),
  WPGraphQL_AcfFourImagesModuleBlock: require("@blocks/FourImagesModule"),
  WPGraphQL_AcfTechFeaturesBlock: require("@blocks/TechFeatures"),
  WPGraphQL_AcfMediaTextGalleryBlock: require("@blocks/MediaTextGallery"),
  WPGraphQL_AcfMoodboardImagesBlock: require("@blocks/MoodboardImages"),
  WPGraphQL_AcfMediaGalleryBlock: require("@blocks/MediaGallery"),
  WPGraphQL_AcfShapesHeroBlock: require("@blocks/ShapesHero"),
  WPGraphQL_CoreHeadingBlock: require("@blocks/Heading"),
  WPGraphQL_AcfAwardsBlock: require("@blocks/Awards"),
  WPGraphQL_AcfTeamBlock: require("@blocks/Team"),
  WPGraphQL_AcfMarqueeBlock: require("@blocks/Marquee"),
  WPGraphQL_AcfArrowsBlock: require("@blocks/ArrowsAnimated"),
  WPGraphQL_AcfPlaygroundBlock: require("@blocks/Playground"),
};
