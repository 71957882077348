import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FadeInOnView from "@components/FadeInOnView";
import Image from "@components/Image";
import Video from "@components/Video";

import { tablet } from "@styles/breakpoints";

import "./style.css";

const DEFAULT_MEDIA_TYPE = "image";
const DEFAULT_BACKGROUND_COLOR = "white";
const DEFAULT_MARGIN_SETTING = "normal";
const DEFAULT_HEADER_COLOR = "auto";

const MediaFrame = ({ acf }) => {
  const [playing, setPlaying] = useState(false);
  const [media, setMedia] = useState(acf?.media);

  const ref = useRef();

  const mediaUrl = media?.mediaItemUrl ?? "";
  const mediaType = media?.mediaType ?? DEFAULT_MEDIA_TYPE;

  const backgroundColor =
    (acf?.framed && acf?.backgroundColor) ?? DEFAULT_BACKGROUND_COLOR;

  const framed = acf?.framed ?? true;
  const pBottomNone = acf?.paddingBottomNone ?? true;
  const link = acf?.link ?? "";

  const url = link.url;
  const linksToVideo = link.linksToVideo;

  const isVideo = mediaType !== DEFAULT_MEDIA_TYPE;

  const classes = classnames(
    "media-frame",
    {
      "margin-side": framed,
      "media-frame--padding-bottom-none": pBottomNone,
    },
    `margin-top--${acf?.mediaFrame_marginTop ?? DEFAULT_MARGIN_SETTING}`,
    `margin-bottom--${acf?.mediaFrame_marginBottom ?? DEFAULT_MARGIN_SETTING}`
  );
  const framedClasses = classnames(
    {
      framed,

      "cursor-type": isVideo || link,
      "cursor-type--play": isVideo && !playing,
      "cursor-type--pause": isVideo && playing,
      "cursor-type--link": !isVideo && url && !linksToVideo,
      "cursor-type--watch": url && linksToVideo,
    },
    `header-color--${acf?.mediaFrame_headerColor ?? DEFAULT_HEADER_COLOR}`
  );

  const onClickVideo = event => {
    const video = event.target;
    if (!video.paused) {
      video.pause();
      setPlaying(false);
    } else {
      video.play();
      setPlaying(true);
    }
  };
  const onClickLink = () => window.open(url, "_blank");

  const onClick = isVideo ? onClickVideo : url ? onClickLink : () => null;

  const Media = !isVideo ? Image : Video;

  useEffect(() => {
    if (!acf?.alternativeMedia) {
      return;
    }

    const onResize = () => {
      const newMedia =
        window.innerWidth < tablet ? acf?.alternativeMedia : acf?.media;
      if (newMedia !== media) {
        setMedia(newMedia);
      }
    };
    onResize();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  return (
    <div
      ref={ref}
      className={classes}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <FadeInOnView className={framedClasses} translate="large">
        <Media
          src={mediaUrl}
          objectFit="contain"
          onClick={onClick}
          autoPlayInView
          setPlaying={setPlaying}
          loop
        />
      </FadeInOnView>
    </div>
  );
};

MediaFrame.propTypes = {
  acf: PropTypes.object.isRequired,
};

export default MediaFrame;
