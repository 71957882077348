import { quadOut } from "eases";
import PropTypes from "prop-types";
import React, { useRef, useMemo, useCallback, useEffect } from "react";
import { useThree, useFrame } from "react-three-fiber";
import { Vector3 } from "three";

import BoxCollider from "@components/BoxCollider";
import { useVisibleSizeAtZDepth } from "@utils/visibleSizeAtZDepth";
import useIsMedia from "@utils/useIsMedia";
import useGLTFLoader from "@utils/useGLTFLoader";
import useGLTFSize from "@utils/useGLTFSize";

import { useConfig } from "./configs";
import useRadius from "./useRadius";

const MAX_GRAVITY_FORCE = 250;
const MAX_GRAVITY_DISTANCE = 1440; // distance at which the gravity is 0
const POINTER_MOVE_FORCE_FACTOR = 1.5;
const POINTER_MOVE_LIFETIME = 0.1;
const ANGULAR_FACTOR = [1, 1, 1];
const LINEAR_FACTOR = [1, 1, 1];
const ANGULAR_DAMPING = 0.9;
const LINEAR_DAMPING = 0.75;
const NULL_FACTOR = [0, 0, 0];
const OPEN_SCALE = 0.36;
const OPEN_SCALE_MOBILE = 0.4;
const POSITION_DAMPING = 0.1;
const ROTATION_DAMPING = 0.05;
const SCALE_DAMPING = 0.06;

const PI2 = Math.PI * 2;

const Shape = props => {
  const {
    id,
    source,
    progressAngle,
    progressRadius,
    scale,
    firstVisit: isFirstVisit,
    open: isOpen,
    active: isActive,
    pointerForceEnabled: isPointerForceEnabled,
    onPointerUp,
    onPointerOver,
    onPointerMove,
    onPointerOut,
    paused,
  } = props;
  const ref = useRef(null);
  const api = useRef(null);
  const gravityCenter = useRef(new Vector3());
  const startFromDefaultPosition = useRef(false);
  const firstPositioning = useRef(true);
  const pointerMove = useRef({});

  // recycled instances
  const v3 = useRef(new Vector3());
  const force = useRef(new Vector3());

  const { clock } = useThree();

  const showBoxColliderWireframe = useConfig(
    config => config.contactMaterial.wireframe
  );

  // load gltf
  const gltf = useGLTFLoader(source);
  const { isMobile } = useIsMedia();

  // get size for bounding box
  const size = useGLTFSize(gltf, scale);
  // get position and scale for project when opened/clicked
  const { width, height } = useVisibleSizeAtZDepth(300);
  const { openPosition, openScale } = useMemo(() => {
    if (!size) {
      return {
        openPosition: [0, 0, 300],
        openScale: OPEN_SCALE,
      };
    }

    const minAvailableSize = Math.min(width, height);
    const s = Math.max(size[0], size[1]);

    return {
      openPosition: isMobile ? [0, height * 0.17, 300] : [0, 0, 300],
      openScale:
        (minAvailableSize * (isMobile ? OPEN_SCALE_MOBILE : OPEN_SCALE)) / s,
    };
  }, [width, height, size, isMobile]);

  // Event handlers
  const handleColliderReady = useCallback(
    ({ ref: cannonRef, api: cannonApi }) => {
      ref.current = cannonRef;
      api.current = cannonApi;
    },
    []
  );
  const handlePointerDown = useCallback(
    event => {
      if (paused) {
        return;
      }

      // Only the mesh closest to the camera will be processed
      event.stopPropagation();
      // Capture the target
      event.target.setPointerCapture(event.pointerId);
    },
    [paused]
  );
  const handlePointerOver = useCallback(
    event => {
      if (paused) {
        return;
      }

      event.stopPropagation();
      onPointerOver({ id, object: gltf.scene });
    },
    [onPointerOver, id, gltf, paused]
  );
  const handlePointerOut = useCallback(
    event => {
      if (paused) {
        return;
      }

      event.stopPropagation();
      onPointerOut({ id, object: gltf.scene });
    },
    [onPointerOut, id, gltf, paused]
  );
  const handlePointerUp = useCallback(
    event => {
      if (paused) {
        return;
      }

      event.stopPropagation();
      // Release capture
      event.target.releasePointerCapture(event.pointerId);

      onPointerUp({ id, object: gltf.scene });
    },
    [onPointerUp, id, gltf, paused]
  );
  const handlePointerMove = useCallback(
    event => {
      if (paused) {
        return;
      }

      const { point } = event;
      const now = clock.getElapsedTime();

      // Init movement
      if (!pointerMove.current.startTime) {
        pointerMove.current.startTime = now;
        pointerMove.current.startPoint = point;
      }

      // Calculate force direction
      pointerMove.current.direction = v3.current
        .subVectors(pointerMove.current.startPoint, point)
        .normalize()
        .toArray();

      // Calculate force's magnitude
      const distance = point.distanceTo(pointerMove.current.startPoint);
      const deltaTime = now - pointerMove.current.startTime;
      pointerMove.current.force = deltaTime
        ? (distance / deltaTime) * POINTER_MOVE_FORCE_FACTOR
        : 0;

      pointerMove.current.endTime = now;
    },
    [clock, paused]
  );
  const handlePrimitivePointerMove = useCallback(() => {
    if (paused) {
      return;
    }

    onPointerMove();
  }, [onPointerMove, paused]);

  useEffect(() => {
    if (isActive) {
      // activating, so set position to default one
      startFromDefaultPosition.current = true;
      // re-set the gravity center
      gravityCenter.current.set(0, 0, 0);
    } else {
      // set the gravity center to top
      gravityCenter.current.set(0, 1440, 0);
    }
  }, [isActive]);

  const radius = useRadius();
  const position = useMemo(
    () => [
      radius * (1 + progressRadius * 0.5) * Math.cos(progressAngle * PI2),
      radius * (1 + progressRadius * 0.5) * Math.sin(progressAngle * PI2),
      0,
    ],
    [radius, progressRadius, progressAngle]
  );
  const aggregatePosition = useMemo(
    () => [
      radius * 0.15 * Math.cos(progressAngle * PI2),
      radius * 0.15 * Math.sin(progressAngle * PI2),
      0,
    ],
    [radius, progressAngle]
  );

  useFrame(({ clock }) => {
    if (paused) {
      return;
    }

    if (!api.current) {
      return;
    }

    ref.current.current.scale.x +=
      ((isOpen ? openScale : 1) - ref.current.current.scale.x) * SCALE_DAMPING;
    ref.current.current.scale.y +=
      ((isOpen ? openScale : 1) - ref.current.current.scale.y) * SCALE_DAMPING;
    ref.current.current.scale.z +=
      ((isOpen ? openScale : 1) - ref.current.current.scale.z) * SCALE_DAMPING;

    let {
      x: currentPositionX,
      y: currentPositionY,
      z: currentPositionZ,
    } = ref.current.current.position;

    if (startFromDefaultPosition.current) {
      if (isFirstVisit && firstPositioning.current) {
        currentPositionX = position[0];
        currentPositionY = position[1];
        currentPositionZ = position[2];
      } else {
        currentPositionX = aggregatePosition[0];
        currentPositionY = aggregatePosition[1];
        currentPositionZ = aggregatePosition[2];
      }
      api.current.velocity.set(0, 0, 0);
      firstPositioning.current = false;
      startFromDefaultPosition.current = false;
    }

    let targetPositionX, targetPositionY, targetPositionZ;
    if (isOpen) {
      targetPositionX = openPosition[0];
      targetPositionY = openPosition[1];
      targetPositionZ = openPosition[2];
    } else {
      targetPositionX = currentPositionX;
      targetPositionY = currentPositionY;
      targetPositionZ = 0;
    }

    api.current.position.set(
      currentPositionX +
        (targetPositionX - currentPositionX) * POSITION_DAMPING,
      currentPositionY +
        (targetPositionY - currentPositionY) * POSITION_DAMPING,
      currentPositionZ + (targetPositionZ - currentPositionZ) * POSITION_DAMPING
    );

    api.current.rotation.set(
      ref.current.current.rotation.x +
        (0 - ref.current.current.rotation.x) * ROTATION_DAMPING,
      ref.current.current.rotation.y +
        (0 - ref.current.current.rotation.y) * ROTATION_DAMPING,
      isOpen
        ? ref.current.current.rotation.z +
            (0 - ref.current.current.rotation.z) * ROTATION_DAMPING
        : ref.current.current.rotation.z
    );

    if (isOpen) {
      return;
    }

    // Calculate constant force to the gravity center
    // 1. get direction vector
    force.current
      .subVectors(ref.current.current.position, gravityCenter.current)
      .normalize();
    // 2. get the distance to the gravity center
    const distance = isActive
      ? ref.current.current.position.distanceTo(gravityCenter.current)
      : 0;
    // 3. get force's magnitude following a quad curve on the distance
    const forceMagnitude =
      quadOut(Math.max(1 - distance / MAX_GRAVITY_DISTANCE, 0)) *
      MAX_GRAVITY_FORCE *
      (isActive ? 1 : 4);
    force.current.multiplyScalar(-forceMagnitude);

    // Sum to that force the force applied with pointer movement (if any)
    if (pointerMove.current) {
      if (isPointerForceEnabled && pointerMove.current.force) {
        // 1. build force vector related to pointer movement
        v3.current
          .set(...pointerMove.current.direction)
          .multiplyScalar(-pointerMove.current.force);

        // 2. sum it to the current force vector
        force.current.add(v3.current);

        // 3. apply the force once per movement update
        pointerMove.current.force = 0;
      }

      // 4. clear move when too much time has passed from last pointer update
      if (
        clock.getElapsedTime() - pointerMove.current.endTime >
        POINTER_MOVE_LIFETIME
      ) {
        pointerMove.current.startTime = null;
      }
    }

    // don't apply force on z axis
    force.current.z = 0;

    // Apply resultant force
    api.current.applyForce(force.current.toArray(), [0, 0, 0]);
  });

  if (!gltf) {
    return null;
  }

  return (
    <>
      {!size && <primitive object={gltf.scene} />}
      {size && (
        <BoxCollider
          size={size}
          position={isFirstVisit ? position : aggregatePosition}
          angularFactor={isOpen || paused ? NULL_FACTOR : ANGULAR_FACTOR}
          linearFactor={isOpen || paused ? NULL_FACTOR : LINEAR_FACTOR}
          linearDamping={LINEAR_DAMPING}
          angularDamping={ANGULAR_DAMPING}
          renderOrder={1}
          wireframe={showBoxColliderWireframe}
          onReady={handleColliderReady}
          onPointerMove={handlePointerMove}
        >
          <primitive
            object={gltf.scene}
            scale={[scale, scale, scale]}
            onPointerOver={handlePointerOver}
            onPointerMove={handlePrimitivePointerMove}
            onPointerOut={handlePointerOut}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
          />
        </BoxCollider>
      )}
    </>
  );
};

Shape.propTypes = {
  source: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  scale: PropTypes.number,
  onPointerOver: PropTypes.func,
  onPointerOut: PropTypes.func,
  onPointerUp: PropTypes.func,
};
Shape.defaultProps = {
  source: null,
  url: null,
  title: null,
  description: null,
  open: false,
  scale: 1,
  onPointerOver: f => f,
  onPointerOut: f => f,
  onPointerUp: f => f,
};

export default Shape;
