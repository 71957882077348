import React from "react";

import ParallaxCarousel from "@components/ParallaxCarousel";

const DEFAULT_HEADER_COLOR = "auto";

const MoodboardImages = ({ acf }) => {
  const images = acf?.images?.map(item => item.image.url);

  return (
    <ParallaxCarousel
      images={images.map(url => ({
        url,
        title: null,
        year: null,
      }))}
      autoScroll={images.length > 6}
      headerColor={acf?.moodboard_headerColor ?? DEFAULT_HEADER_COLOR}
    />
  );
};

export default MoodboardImages;
