import classnames from "classnames";
import React from "react";
import useInView from "use-in-view";

const InViewWrapper = ({ children, offset = 0 }) => {
  const [ref, isInView] = useInView(offset);

  return (
    <div
      ref={ref}
      className={classnames("in-view-wrapper", {
        "in-view-wrapper--active": isInView,
      })}
    >
      {typeof children === "function" ? children(isInView) : children}
    </div>
  );
};

export default InViewWrapper;
